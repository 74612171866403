import React, { useCallback, useEffect, useState } from "react"
import BootstrapModalDialog from "Content/BootstrapModalDialog"
import useSessionExpiry, { MY_ROR_USER_ID_EVENT } from "./useSessionExpiry"

const threshhold = 60 * 15 // show warning when this many seconds remain
const lifetime = 60 * 60 // how many seconds the session lasts
const SessionExpiryAlertBootstrap: React.FC<{ userId: number | undefined }> = ({ userId }) => {
  // monitor ajax requests
  const handleAjaxComplete = useCallback((evt: any, xhr: any) => {
    const userId = xhr.getResponseHeader("X-myror-user-id")
    if (userId) {
      document.dispatchEvent(new CustomEvent(MY_ROR_USER_ID_EVENT, { detail: userId }))
    }
  }, [])
  useEffect(() => {
    $(document).on("ajaxComplete", handleAjaxComplete)
    return () => {
      $(document).off("ajaxComplete", handleAjaxComplete)
    }
  }, [handleAjaxComplete])

  const [currentUserId, remaining] = useSessionExpiry(userId, lifetime)
  const [loading, setLoading] = useState(false)
  const handleFreshen = useCallback(() => {
    setLoading(true)
    $.get("/").then(() => setLoading(false))
  }, [setLoading])
  const handleSignIn = useCallback(() => {
    setLoading(true)
    window.location.reload()
  }, [setLoading])

  if (!userId) {
    return null
  }

  if (currentUserId !== userId) {
    return (
      <BootstrapModalDialog title="Session invalid">
        <div className="mb-3 text-leader">{`You may have signed out in another window`}</div>
        <div className="text-right">
          {loading && <span className="mr-2">Loading &hellip;</span>}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSignIn}
            disabled={loading}
          >
            Continue
          </button>
        </div>
      </BootstrapModalDialog>
    )
  }

  if (remaining < threshhold) {
    return (
      <BootstrapModalDialog title={remaining === 0 ? "Session Expired" : "Are you still there?"}>
        {remaining === 0 && (
          <>
            <div className="mb-3 text-leader">{`Your session has expired`}</div>
            <div className="text-right">
              {loading && <span className="mr-2">Loading &hellip;</span>}
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSignIn}
                disabled={loading}
              >
                Continue
              </button>
            </div>
          </>
        )}

        {remaining > 0 && (
          <>
            <div className="mb-3 text-leader">{`Your session will expire soon!`}</div>
            <div className="text-right">
              {loading && <span className="mr-2">Loading &hellip;</span>}
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFreshen}
                disabled={loading}
              >
                Keep me signed-in
              </button>
            </div>
          </>
        )}
      </BootstrapModalDialog>
    )
  }
  return null
}
export default SessionExpiryAlertBootstrap
