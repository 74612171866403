import React, { Component } from "react"
import { Measure, Question, Site, ValidationCondition } from "../types"
import CollapsableSiteSection from "./CollapsableSiteSection"
import SiteDataTable from "./SiteDataTable"
import SiteDataView from "./SiteDataView"
import SurveyContext from "SiteSurveyDemographics/SurveyContext"

type State = {
  measures: Measure[]
  totalValue: number
}
type Props = {
  collapsable: boolean
  question: Question
  locationName: string
  measures: Measure[]
  site: Site
  validated: boolean
  validationCondition: ValidationCondition
}
export default class SiteSection extends Component<Props, State> {
  static contextType = SurveyContext
  context!: React.ContextType<typeof SurveyContext>

  constructor(props: Props) {
    super(props)
    const measures = props.measures.map((measure) => Object.assign({}, measure))
    this.state = {
      measures: measures,
      totalValue: this.calculateTotalValue(measures)
    }
  }

  calculateTotalValue(measures: Measure[]) {
    return measures.reduce((acc, measure) => {
      const value = measure.answerValue ? parseFloat(measure.answerValue) : 0
      return isNaN(value) ? acc : acc + value
    }, 0)
  }

  render() {
    const {
      collapsable,
      question,
      locationName,
      site,
      validated,
      validationCondition
    } = this.props
    const { measures, totalValue } = this.state
    const initiallyCollapsed = !(validated && totalValue !== 100)

    const {displayType} = this.context
    const DataTableComp = displayType === 'input' ? SiteDataTable : SiteDataView
    const dataTable = (
      <DataTableComp
        totalValue={totalValue}
        question={question}
        locationName={locationName}
        measures={measures}
        onValueChange={this.handleMeasureChange}
        site={site}
        validated={validated}
        validationCondition={validationCondition}
      />
    )

    if (collapsable) {
      return (
        <CollapsableSiteSection
          totalValue={totalValue}
          site={site}
          dataTable={dataTable}
          collapsed={initiallyCollapsed}
          validated={validated}
          validationCondition={validationCondition}
        />
      )
    } else {
      return dataTable
    }
  }

  handleMeasureChange = (measureId: string, changeSet: Partial<Measure>) => {
    this.setState(({ measures }) => {
      const newMeasures = measures.map((measure) => {
        if (measure.id == measureId) {
          const answerValue = changeSet.answerValue
            ? parseFloat(changeSet.answerValue)
            : undefined
          const errors =
            answerValue && (answerValue < 0 || answerValue > 100)
              ? { errors: ["must be between 0 and 100"] }
              : { errors: [] }
          return Object.assign({}, measure, changeSet, errors)
        } else {
          return measure
        }
      })

      return {
        measures: newMeasures,
        totalValue: this.calculateTotalValue(newMeasures)
      }
    })
  }
}
