import React from 'react';
import { Site } from './types';

const SearchResultItem = (props: {
  site: Site,
  onChoose: (s: Site) =>void
  chosen: boolean
}) => {
  const {site, onChoose, chosen} = props
  return (
    <button
      type="button"
      className="dropdown-item text-wrap"
      value={site.id}
      disabled={chosen}
      onClick={
        () => {onChoose(site)}
      }
      style={chosen ? {backgroundColor: '#eceaea'} : undefined}
      key={site.id}
    >
      <div className="small font-weight-bold text-muted">
        {chosen &&
          <span className='mr-1'>✓</span>
        }
        Site ID {site.ident}
      </div>
      <div>{site.name}</div>
    </button>
  )
}
export default React.memo(SearchResultItem)
