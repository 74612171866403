import { useCallback, useEffect, useState } from "react"

const getTimestamp = () => {
  const now = new Date()
  return now.getTime() / 1000
}

const pollSeconds = 5

const saveValue = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, String(value))
  } catch {
    undefined
  }
}
const getValue = (key: string) => {
  try {
    const value = window.localStorage.getItem(key)
    const number = value ? parseInt(value, 10) : NaN
    return isNaN(number) ? undefined : number
  } catch {
    undefined
  }
}

export const MY_ROR_USER_ID_EVENT = "MyRorUserIdSet"
let safetyCount = 0
const ts_key = "session_last_request_ts"
const uid_key = "session_user_id"
const useSessionExpiry = (initialUserId: number | undefined, lifetime: number) => {
  const [remaining, setRemaining] = useState(Number.MAX_VALUE)
  const [currentUserId, setCurrentUserId] = useState(initialUserId)

  // persist our values on load
  useEffect(() => {
    saveValue(ts_key, getTimestamp())
    saveValue(uid_key, initialUserId)
  }, [initialUserId])

  const [reqCount, setReqCount] = useState(0)
  const handleSetUserId = useCallback<EventListener>((evt: Event) => {
    const userId = (evt as CustomEvent).detail
    if (userId) {
      setReqCount((i) => i + 1)
      saveValue(ts_key, getTimestamp())
      saveValue(uid_key, userId)
    }
  }, [])
  useEffect(() => {
    document.addEventListener(MY_ROR_USER_ID_EVENT, handleSetUserId)
    return () => {
      document.removeEventListener(MY_ROR_USER_ID_EVENT, handleSetUserId)
    }
  }, [handleSetUserId])

  // poll storage to see if values have changed
  useEffect(() => {
    // IE / Edge Hack - add noop storage listener so cross-tab values are visible
    window.onstorage = function () {}
    let interval: NodeJS.Timeout
    const poll = () => {
      // console.info("poll", safetyCount, reqCount)
      safetyCount += 1
      // stop polling after a while
      if (safetyCount > (lifetime * 5) / pollSeconds) {
        setRemaining(0)
        return
      }
      const uid = getValue(uid_key)
      if (uid !== initialUserId) {
        setCurrentUserId(uid)
      }
      const ts = getValue(ts_key)
      if (ts) {
        const expires = ts + lifetime
        const delta = expires - getTimestamp()
        const floored = delta > 0 ? delta : 0
        setRemaining(floored)
        if (floored > 0) {
          interval = setTimeout(poll, pollSeconds * 1000)
        }
      }
    }
    poll()
    return () => {
      if (interval) {
        clearTimeout(interval)
      }
      window.onstorage = null
    }
  }, [setRemaining, setCurrentUserId, initialUserId, lifetime, reqCount])

  return [currentUserId, remaining] as const
}
export default useSessionExpiry
