// Select2 for Simple Form initializer

import "select2/dist/js/select2.full"

export default function () {
  var prepareSelect2Options = function (options, $input) {
    var select2Options = {}

    /*
    var sanitizeInputValues = function (input) {
      return input.val().replace(/\[|\]|"|'/g, "")
    }
    */

    select2Options.placeholder = options.placeholder

    // Check for multiple
    if (options.multiple) {
      select2Options.multiple = true

      // When initialize the Select2 element, checks if it's multiple or not.
      // In the first case, it will use all the data retrieved. Otherwise,
      // will use the first item because it works in this way and assumes
      // the widget is in single mode.
      var $form = $input.closest("form")
      $form.on("submit", function () {
        var inputVal = String($input.val())
        if (!inputVal.match(/^\s*$/)) {
          var ids = inputVal.split(",")
          for (var i in ids) {
            $form.append(
              '<input type="hidden" name="' + $input.attr("name") + '" value="' + ids[i] + '">'
            )
          }
        }
        $input.prop("disabled", true)
      })
    }

    // Check for creation box when there were no results
    if (options.can_create_on_empty_result) {
      select2Options.formatNoMatches = function () {
        return (
          '<span style="cursor: pointer;" onclick="openTab(\'' +
          options.can_create_on_empty_result.url +
          '\')"> <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> ' +
          options.can_create_on_empty_result.label +
          "</span>"
        )
      }
    }

    // allow the creation of new values willy-nilly
    if (options.tags) {
      select2Options.tags = true
    }

    // Check AJAX options
    if (options.ajax) {
      throw new Error("unexpected select2 ajax")
    } else {
      select2Options.minimumResultsForSearch = 8
    }

    if (options.void_option) {
      $input.append($('<option value="">' + options.void_option + "</option>"))
    }

    if (options.i18n) {
      throw new Error("unexpected select2 i18n")
    }

    $.extend(select2Options, options.opts_for_select2 || {})

    return select2Options
  }

  var initializeSelect2SimpleForm = function () {
    var $this = $(this)
    var dataOptions = $this.data("options") || {}
    var initializerOptions = prepareSelect2Options(dataOptions, $this)
    var $modal = $this.closest("#j-page-modal-ctl")
    if ($modal.length) {
      initializerOptions.dropdownParent = $modal
    }
    $this.select2(initializerOptions)

    // Post plugins for Select2
    if (dataOptions.sortable) {
      throw new Error("unexpected select2 sortable")
    }
  }

  return {
    init: function (selector) {
      $(selector).find('[data-ui="select2-simpleform"]').each(initializeSelect2SimpleForm)
    }
  }
}
