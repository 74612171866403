import clsx from "clsx"
import React, { FC } from "react"
import useUniqueHtmlId from "Content/Labeled/useUniqueHtmlId"

// Deprecate for material ui
const ContentLabeledBooleanTag: FC<{
  label: string
  checked: boolean
  value?: string | null
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  name?: string
  errors?: string[]
  disabled?: boolean
}> = ({ label, value, checked, onChange, name, disabled, errors = [] }) => {
  const invalid = errors.length > 0
  const htmlId = useUniqueHtmlId("boolean")

  return (
    <div className="form-check">
      {name && <input type="hidden" name={name} value={""} />}
      <input
        type="checkbox"
        className={clsx("form-check-input", invalid && "is-invalid")}
        id={htmlId}
        value={value ? value : undefined}
        checked={!!checked}
        disabled={!!disabled}
        name={name}
        onChange={onChange}
      />
      <label htmlFor={htmlId}>{label}</label>
      {errors.map((msg) => (
        <div key={msg} className="invalid-feedback">
          {msg}
        </div>
      ))}
    </div>
  )
}

export default ContentLabeledBooleanTag
