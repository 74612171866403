import React, { FC, useCallback, useState } from "react"
import Form from "./Form"
import { HealthSystemRequest } from "./types"
import snakeCase from "lodash/snakeCase"

const initialState: HealthSystemRequest = {
  healthSystemId: "",
  requestType: "join",
  newHealthSystemName: "",
  newHealthSystemWebsiteUrl: "",
  newHealthSystemPhone: ""
}

const App: FC<{
  formPrefix: string
  healthSystems: { id: string; label: string }[]
}> = ({ formPrefix, healthSystems }) => {
  const formFieldName = useCallback(
    (field: keyof HealthSystemRequest) => `${formPrefix}[${snakeCase(field)}]`,
    [formPrefix]
  )
  const [request, setRequest] = useState(initialState)

  const onChangeRequest = useCallback(
    (values: Partial<HealthSystemRequest>) => setRequest({ ...request, ...values }),
    [request]
  )

  return (
    <Form
      healthSystems={healthSystems}
      request={request}
      onChange={onChangeRequest}
      formFieldName={formFieldName}
    />
  )
}
export default App
