import React, { useCallback, useState, useRef } from "react"
import { Site } from "./types"
import SearchInput from "./SearchInput"
import SearchResults from "./SearchResults"
import useDebounce from "react-use/esm/useDebounce"
import useAsync from "react-use/esm/useAsync"

export interface SiteSearchProps {
  siteQueryUrl: string
  selectedSiteIds: number[]
  handleSelectSite: (site: Site) => any
  title?: string
  params?: Record<string, string>
}

const SiteSearch: React.FC<SiteSearchProps> = ({
  siteQueryUrl,
  selectedSiteIds,
  handleSelectSite,
  title,
  params = {}
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [bouncedSearchTerm, setBouncedSearchTerm] = useState("")
  const [focused, setFocus] = useState(false)

  useDebounce(() => setBouncedSearchTerm(searchTerm), 300, [searchTerm])

  const searchQuery = useAsync<[string, Site[]]>(() => {
    if (siteQueryUrl && (focused || bouncedSearchTerm)) {
      const url = new URL(siteQueryUrl, window.origin)
      url.searchParams.append("term", bouncedSearchTerm)
      Object.entries(params).forEach(([param, value]) => {
        url.searchParams.append(param, value)
      })
      return fetch(url.toString())
        .then((res) => res.json())
        .then((data) => [bouncedSearchTerm, data.results])
    } else {
      return Promise.resolve(["", []])
    }
  }, [siteQueryUrl, bouncedSearchTerm, focused])
  const [queriedTerm, queriedResults] = searchQuery.value ? searchQuery.value : ["", []]
  const loading = searchQuery.loading || queriedTerm !== searchTerm

  const rootRef = useRef(null)

  const handleChooseSite = useCallback(
    (site: Site) => {
      setFocus(false)
      setSearchTerm("")
      handleSelectSite(site)
    },
    [handleSelectSite]
  )

  return (
    <div>
      {title && <h3>{title}</h3>}
      <div ref={rootRef}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          onFocus={() => setFocus(true)}
          onBlur={() =>
            /**
             * If a user clicks on a search result, this blur handler fires before the onClick handler fires on the
             * result. Without a timeout here, this will cause the search results to unmount before the onClick handlers
             * can fire. A 100ms timeout gives enough time for the onClick handlers to fire before they unmount.
             */
            setTimeout(() => {
              setFocus(false)
              setSearchTerm("")
            }, 250)
          }
          focused={focused}
          loading={loading}
        />
        {(focused || searchTerm) && (
          <SearchResults
            selectedSiteIds={selectedSiteIds}
            onChoose={handleChooseSite}
            loading={loading}
            error={!!searchQuery.error}
            results={searchQuery && !loading ? queriedResults : []}
          />
        )}
      </div>
    </div>
  )
}

export default SiteSearch
