import React, { memo } from 'react';
import { useStateValue } from 'SiteContactManager/state';
import { Contact } from '../../types';

const SiteContactView = (
  props: {
    contact: Contact
  }
) => {
  const { contact } = props
  const { site } = contact
  const [{ roles }] = useStateValue();
  const role = roles.find((role) => role.id == contact.role_id)

  return (
    <div>
      <div className="mb-2">
        <div>
          <span className="text-id-value-pfx">Site ID</span>
          <span className="text-id-value">{site.ident}</span>
        </div>
        <h3 className="mb-0">
          {site.permission.show ? <a href={site.url} className="text-color-black" target="ror_site">{site.name}</a> : site.name}
        </h3>
      </div>
      <div>
        <strong>Role at this site: </strong>
        {role ? role.label : ''}
      </div>
      {(contact.primary_contact) && (
        <div>
          <div className="icon-svg-wrapper"><svg className="primary-contact-star">
            <use xlinkHref="#icon-star" />
          </svg></div>
          Primary Contact
        </div>
      )}
    </div>
  )
}

export default memo(SiteContactView)
