import React, { useRef } from 'react'

const SearchInput = (props: {
  loading: boolean,
  value: string
  onChange: (e: React.SyntheticEvent<{ value: string }>) => void
  onBlur?: (event: any) => void
  onFocus?: (event: any) => void
  focused: boolean
  actionLabel?: string
}) => {
  const inputEl = useRef<HTMLInputElement>(null)
  return (
    <div className="input-group">
      <input
        ref={inputEl}
        className="form-control"
        type="text"
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-secondary"
          disabled={props.focused}
          style={{width: '137px', overflow: 'hidden', 'whiteSpace': 'nowrap'
        }}
          onClick={
            () => inputEl.current ? inputEl.current.focus() : null
          }
        >
          {props.loading
            ? 'Loading ...'
            : <>
              <i className="i-plus" />
              {props.actionLabel || 'Add'}
            </>
          }
      </button>
      </div>
    </div>
  )
}
export default SearchInput
