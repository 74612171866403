import "trumbowyg"

const setupWysiwyg = function () {
  var trumboOptsDefault = {
    btns: [
      ["strong", "em"],
      ["link", "horizontalRule", "unorderedList", "orderedList"],
      ["viewHTML", "removeformat"]
    ],
    removeformatPasted: true,
    urlProtocol: "http",
    minimalLinks: true
  }
  var trumboOptsSimple = {
    btns: [
      ["strong", "em"],
      ["horizontalRule", "unorderedList", "orderedList"],
      ["viewHTML", "removeformat"]
    ],
    removeformatPasted: true,
    urlProtocol: "http",
    minimalLinks: true
  }
  var trumboOptsAdmin = {
    btns: [
      ["strong", "em"],
      ["link", "horizontalRule", "unorderedList", "orderedList", "formatting"],
      ["viewHTML", "removeformat"]
    ],
    removeformatPasted: true,
    urlProtocol: "http",
    minimalLinks: true
  }
  return function ($root) {
    $.trumbowyg.svgPath = window.trumbowygIconPath
    $root.find(".wysiwyg-control").trumbowyg(trumboOptsDefault)
    $root.find(".wysiwyg-control-simple").trumbowyg(trumboOptsSimple)
    $root.find(".wysiwyg-control-admin").trumbowyg(trumboOptsAdmin)

    $root
      .find(".wysiwyg-control, .wysiwyg-control-simple, .wysiwyg-control-admin")
      .each(function () {
        var $ctrl = $(this)
        var $form = $ctrl.closest("form")
        if ($form.length) {
          $form.on("submit", function () {
            $ctrl.trumbowyg("execCmd", "syncTextarea")
          })
        }
      })
  }
}
export default setupWysiwyg
