import React, { createContext, useContext, useReducer } from 'react';
import { SrgSiteAction, SrgSiteManagerState, SrgSite } from './types';
import { Site } from 'SiteSearch/types';

const addSite = (state: SrgSiteManagerState, site: Site): SrgSiteManagerState => {
  const found = state.sites.find(s => s.site.id == site.id )

  const newSite = found
    ? {...found, deleted: false}
    : { site, useAggregateData: true, deleted: false } as SrgSite

  return {
    ...state,
    modified: true,
    sites: state.sites.filter((s) => s != found).concat(newSite)
  }
}

const updateSite = (
  state: SrgSiteManagerState,
  site: Site,
  updates: Partial<SrgSite>
): SrgSiteManagerState => {
  return {
    ...state,
    modified: true,
    sites: state.sites.map(
      (s) => (
        s.site.id === site.id
          ? { ...s, ...updates}
          : s
      )
    )
  }
}

const contactReducer = (
  state: SrgSiteManagerState, action: SrgSiteAction
): SrgSiteManagerState => {
  const {site} = action.data
  switch (action.type) {
    case 'SITE_CREATED':
      return addSite(state, site)
    case 'SITE_REMOVED':
      return updateSite(state, site, {deleted: true})
    case 'SITE_RESTORED':
      return updateSite(state, site, {deleted: false})
    case 'SITE_AGGREGATION_UPDATED':
      return updateSite(state, site, { useAggregateData: action.data.useAggregateData })
    default:
      return state
  }
}

type context = [SrgSiteManagerState, React.Dispatch<SrgSiteAction>]
const fakeState:SrgSiteManagerState = {
  sites: [],
  formPrefix: '',
  siteQueryUrl: null,
  user: {name: '', confirmed_site_ids: []}
}
export const StateContext = createContext<context>([fakeState, () => undefined]);
export const StateProvider = (props: {
  initialState: SrgSiteManagerState
  children: any
}) => {
  const {initialState, children} = props
  return (
    <StateContext.Provider
      value={ useReducer(contactReducer, initialState) }
    >
      {children}
    </StateContext.Provider>
  )
}
export const useStateValue = () => useContext(StateContext);
