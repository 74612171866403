'use strict'
$(function() {
  var $modalBody = $('#j-page-modal-body');
  var $modalTitle = $('#j-page-modal-title');
  var $modalCtl = $('#j-page-modal-ctl');
  var $modalDialog = $('#j-page-modal-dialog');

  /**
   * Copied from https://stackoverflow.com/questions/41121577/ckeditor-link-input-not-working-in-modal/43104663#43104663
   *
   * This allows other modal constructions (trumbowyg/flatpicker/etc.) to escape the bootstrap modal focus trap.
   * Typically these other modals would be attached to body rather than the modal itself, and the default modal behavior
   * is to redirect focus if we try to focus on anything outside of the modal, which would break those other modals.
   * This override allows other items placed after the modal in the body to take focus.
   */
  $modalCtl.modal.Constructor.prototype._enforceFocus = function() {
    const eventName = 'focusin.modal';
    $(document)
      .off(eventName) // Guard against infinite focus loop
      .on(eventName, event => {
        if (document !== event.target &&
          this._element !== event.target &&
          $(this._element).has(event.target).length === 0 &&
          $(event.target).parentsUntil('*[role="dialog"]').length === 0
        ) {
          this._element.focus()
        }
      })
  }

  var errorMessage = function(content) {
    if (!content) {
      content = 'Something went wrong, you may have a network connection problem.';
    }
    var buttons = '<button type="button" class="btn btn-secondary j-page-reload">Reload</button>';
    return '<div class="">' + content + '</div>' + '<div class="text-right">' + buttons + '</div>'
  }

  var confirmClose = false;
  var jqXHR;
  var renderModal = function(state) {
    switch(state.status) {
      case 'closed':
        $modalBody.html('');
        $modalCtl.modal('hide');
        break;
      case 'open':
        $modalTitle.html(state.contentTitle);
        $modalBody.html(state.contentHtml);
        $modalBody.scrollTop(0);
        $modalDialog.prop('class', 'modal-dialog modal-' + state.dialogSize)
        if (!$modalCtl.is('.show')) {
          $modalCtl.modal({
            backdrop: 'static',
            keyboard: false,
          });
          $modalCtl.modal('show');
        }
        $modalCtl.trigger('modal:content-changed');
        break;
      case 'requested':
        if (jqXHR) {
          jqXHR.abort()
        }
        setModalState({status: 'loading'})
        if (!state.contentUrl) {
          alert('something went wrong');
          return;
        }
        jqXHR = $.ajax({
          method: 'GET',
          url: state.contentUrl,
          success: function(data, status, xhr) {
            var $parsed = $(data);
            var url = xhr.getResponseHeader('Location');
            if (url) {
              setModalState({
                contentUrl: url,
                status: 'requested'
              });
            } else {
              setModalState({
                status: 'open',
                contentHtml: $parsed.find('#modal-payload-body').html(),
                contentTitle: $parsed.find('#modal-payload-title').html(),
              });
            }
          }
        })
        setModalState({
          status: 'open',
          contentTitle: 'Loading&hellip;',
          contentHtml: $('#j-modal-loading-body').html(),
        });
        jqXHR.fail(function(xhr){
          if (!xhr || xhr.getAllResponseHeaders()) {
            setModalState({ status: 'failed' })
          }
        });
        break;
      case 'canceled':
        if (jqXHR) {
          jqXHR.abort()
        }
        setModalState({
          status: 'closed',
          dialogSize: 'lg', //reset
        })
        break;
      case 'loading':
        break;
      default:
        $modalBody.html(errorMessage());
        $modalCtl.modal('show');
        $modalCtl.trigger('modal:content-changed');
    }
  };

  var initialState = {
    status: 'closed',
    contentUrl: null,
    contentHtml: null,
    contentTitle: null,
    dialogSize: 'lg',
  };
  var currentState = initialState;
  var stateQueue = [];

  var needsProcessing = true;
  var setModalState = function(newState) {
    stateQueue.push(newState);
    if (needsProcessing) {
      needsProcessing = false
      setTimeout(function() {
        processStateQueue();
        needsProcessing = true
      });
    }
  }
  var processStateQueue = function() {
    var safety = 0
    var nextState;
    while (stateQueue.length > 0) {
      safety += 1;
      if (safety > 10) {
        alert('something went wrong');
        return;
      }
      nextState = stateQueue.shift();
      currentState = Object.assign({}, currentState, nextState);
      renderModal(currentState);
    }
  };

  var confMsg = 'Are you sure you want to close this window? You will lose any unsaved changes.';
  $(document).on('click', 'a.j-modal-link', function(evt) {
    evt.preventDefault();
    var url = $(evt.target).closest('.j-modal-link').attr('href');
    var confirmed = confirmClose ? confirm(confMsg) : true
    if (confirmed) {
      setModalState({
        contentUrl: url,
        status: 'requested'
      });
      confirmClose = false;
    }
  });
  window.registerBeforeUnload(function() {
    if (confirmClose) {
      return confMsg;
    }
  });
  $modalCtl.on('click', '.j-modal-close', function(evt) {
    evt.preventDefault();
    var confirmed = confirmClose ? confirm(confMsg) : true
    if (confirmed) {
      setModalState({status: 'canceled'});
      confirmClose = false;
    }
  });

  window.modalApi = {
    setState: setModalState,
    setConfirmClose: function(val) {
      confirmClose = val;
    },
    openUrl: function(url) {
      setModalState({
        contentUrl: url,
        status: 'requested'
      })
    },
    close: function() {
      setModalState({
        status: 'closed',
        dialogSize: 'lg', //reset
      })
    },
    showLoading: function() {
      setModalState({
        status: 'open',
        contentTitle: 'Loading&hellip;',
        contentHtml: $('#j-modal-loading-body').html(),
        dialogSize: 'md',
      });
    },
    showContent: function(content, title) {
      setModalState({
        contentHtml: content,
        contentTitle: title,
        status: 'open',
        dialogSize: 'lg',
      });
    },
    showError: function(msg) {
      setModalState({
        contentHtml: errorMessage(msg),
        contentTitle: 'Error',
        status: 'open',
        dialogSize: 'md',
      });
    }
  };
});
