import clsx from "clsx"
import React from "react"
import useUniqueHtmlId from "Content/Labeled/useUniqueHtmlId"

// Deprecate for material ui
export type SelectOption = { id: string | number; label: string }
const ContentLabeledSelectTag: React.FC<{
  label: string
  options?: SelectOption[]
  value: string | null
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void
  name?: string
  errors?: string[]
  disabled?: boolean
  required?: boolean
}> = ({
  label,
  options = [],
  children,
  value,
  onChange,
  name,
  disabled,
  errors = [],
  required = true
}) => {
  const invalid = errors.length > 0
  const htmlId = useUniqueHtmlId("select")

  return (
    <div className="form-group">
      <label htmlFor={htmlId}>{label}</label>
      <select
        id={htmlId}
        className={clsx("form-control", invalid && "is-invalid")}
        value={value ? value : undefined}
        onChange={onChange}
        name={name}
        required={required}
        disabled={!!disabled}
      >
        {children}
        {options.map((opt) => (
          <option key={opt.id} value={opt.id}>
            {opt.label}
          </option>
        ))}
      </select>
      {errors.map((msg) => (
        <div key={msg} className="invalid-feedback">
          {msg}
        </div>
      ))}
    </div>
  )
}

export default ContentLabeledSelectTag
