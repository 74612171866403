import useUniqueHtmlId from "Content/Labeled/useUniqueHtmlId"
import React, { useLayoutEffect } from "react"

// use bootstrap modal for legacy reasons
const BootstrapModalDialog: React.FC<{ title: string }> = ({ title, children }) => {
  const htmlId = useUniqueHtmlId("modal")
  useLayoutEffect(() => {
    //@ts-ignore
    $(`#${htmlId}`).modal("show")
    return () => {
      //@ts-ignore
      $(`#${htmlId}`).modal("hide")
    }
  }, [htmlId])
  return (
    <>
      <div className="modal-backdrop fade show" style={{ zIndex: 9000 }}></div>
      <div
        id={htmlId}
        aria-labelledby={`${htmlId}-title`}
        className="modal fade show"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
        data-backdrop="static"
        style={{ zIndex: 9010 }}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title title-banner" id={`${htmlId}-title`}>
                {title}
              </h3>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BootstrapModalDialog
