import React from 'react';
import { DataTableProps, Measure } from '../types';

const MeasureRow = ({ measure }: { measure: Measure }) => (
  <tr>
    <td className="text-right" style={{ verticalAlign: 'middle' }}>
      {measure.label} {measure.otherValue}
    </td>
    <td className="text-right">
      {measure.answerValue ? measure.answerValue + ' %' : <span className="text-muted">N/A</span>}
    </td>
  </tr>
)

const SiteDataView = (props: DataTableProps) => {
  const { question, locationName, measures } = props

  return (
    <table className="table table-bordered table-sm mb-0 calculated-results__table page-break-inside">
      <colgroup>
        <col></col>
        <col width="200px"></col>
      </colgroup>
      <thead>
        <tr>
          <th className="text-right">{question.label}</th>
          <th className="text-center">
            <div>{question.hint}</div>
            <div className="calculated-results__secondary-label">at {locationName}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          measures.filter(
            (measure) => !question.dynamicRows || measure.answerValue
          ).map(
            (measure) => (<MeasureRow key={measure.label} measure={measure} />)
          )
        }
      </tbody>
    </table>
  )
}

export default SiteDataView
