import "./preloadPolyfills"
import "legacy"
import "reactLoader"

import ReactDOM from "react-dom"
import SessionExpiryAlertBootstrap from "SessionExpiry/AlertBootstrap"

const root = document.querySelector("#sessionExpiry")
if (root) {
  ReactDOM.render(<SessionExpiryAlertBootstrap userId={window.currentUserId} />, root)
}
