import React, { useMemo } from "react"
import SiteSectionList from "./components/SiteSectionList"
import SurveyContext, { SurveyContextType } from "./SurveyContext"

const App: React.FC<{
  config: React.ComponentProps<typeof SiteSectionList>
  displayType: SurveyContextType["displayType"]
}> = ({ config, displayType }) => {
  const { Provider } = SurveyContext
  const contextValue = useMemo(() => ({ displayType: displayType }), [displayType])
  return (
    <Provider value={contextValue}>
      <SiteSectionList {...config} />
    </Provider>
  )
}
export default App
