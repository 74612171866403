'use strict'
$(function() {
  var updateConditionalInputs = function($input) {
    var value = String($input.val());
    var ident = $input.data('ident');
    $('.j-dependent-input[data-ident=' + ident + ']').each(function() {
      var $dep = $(this);
      //console.info(ident, value, String($dep.data('value')));
      $dep.toggleClass('d-none',
        String($dep.data('value')) != value
      );
    });
  }
  $('.j-dependent-input').addClass('d-none');
  $('.j-option:checked, .j-survey-select').each(function() {
    updateConditionalInputs($(this));
  });
  $('.j-option').click(function() {
    updateConditionalInputs($(this));
  });
  $('.j-survey-select').change(function() {
    updateConditionalInputs($(this));
  });
});

var crudeIntlPolyfill =  function(args) {
  if (window.Intl) {
    return new window.Intl.NumberFormat('en-US', args)
  } else {
    args = args ? args : {};
    return {
      format: function(v) {
        if (args.style == 'percent') {
          return Math.round(v * 100) + '%'
        }
        return (args.style == 'currency') ? ('$' + v) : ('' + v);
      }
    }
  }
}

$(function() {
  var formatter = crudeIntlPolyfill();
  var formatterUSD = crudeIntlPolyfill({
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  var formatterPct = crudeIntlPolyfill({
    style: 'percent',
  })
  var sumCounts = function($group) {
    $group.find('.j-survey-sum-readout').each(function() {
      var $readout = $(this);
      var sum = 0;
      $group.find( $readout.data('target') ).each(function() {
        var $member = $(this);
        var value = $member.is('input') ? $member.val() : $member.html().replace(/,/, '');
        value = isNaN(value) ? 0 : +value;
        sum += value;
      });
      var factor = $readout.data('factor')
      if (factor) {
        sum *= (+factor)
      }
      var format = $readout.data('format');
      var rfmt;
      rfmt = format == 'currency' ? formatterUSD : formatter;
      var result = rfmt.format(sum);
      if (result != $readout.html()) {
        $readout.html( rfmt.format(sum) ).parent().addClass('updated');
      }
    });

    $group.find('.j-survey-sum-bdr').each(function() {
      var $readout = $(this);

      var visits = 0;
      $group.find('.j-well_child_visits').each(function() {
        var $member = $(this);
        var value = $member.is('input') ? $member.val() : $member.html().replace(/,/, '');
        value = isNaN(value) ? 0 : +value;
        visits += value;
      });
      var books = 0;
      $group.find('.j-books_distributed').each(function() {
        var $member = $(this);
        var value = $member.is('input') ? $member.val() : $member.html().replace(/,/, '');
        value = isNaN(value) ? 0 : +value;
        books += value;
      });

      var rate = visits > 0 && books > 0 ? books / visits : 0;
      var rfmt = formatterPct;
      var result = rfmt.format(rate);
      if (result != $readout.html()) {
        $readout.html( rfmt.format(rate) ).parent();
      }
    });
  };

  $('.j-survey-sum-counts input').on('input change js-change', function() {
    sumCounts(
      $(this).closest('.j-survey-sum-counts')
    );
  });

  $('.j-survey-sum-counts').each(function() {
    sumCounts( $(this) );
  });
});

$(function () {
  var url = '/uploads';
  var $uploadFormTemplate = $('<form class= action="#" accept-charset="UTF-8" method="post"><label class="j-open-upload-dialog"><input type="file" name="file" id="file"></label></form>');
  var $uploadForms =  $('#j-direct-uploads');
  $('.j-file-upload').each(function() {
    var $group = $(this);
    var $input = $group.find('.j-file-upload-input');
    var $btn = $group.find('.j-file-upload-btn');
    var $msg = $group.find('.j-file-upload-msg');
    var $deleteButton = $group.find('.j-file-delete-btn');
    $deleteButton.click(function() {
      if (confirm('are you sure you want to remove this file?') ) {
        $input.val('delete');
        $input.trigger('js-change');
        $deleteButton.addClass('d-none');
        $msg.html('file removed');
      }
    });

    //var $newForm = $uploadForms.append(
    //  $uploadFormTemplate.clone()
    //);
    var $newForm = $uploadFormTemplate.clone().appendTo(
      $uploadForms
    );

    $btn.click(function(e) {
      e.preventDefault();
      $newForm.find('.j-open-upload-dialog').click();
    });

    $newForm.fileupload({
      url: url,
      dataType: 'json',
      add: function(e, data) {
        // could allow cancel xhr
        data.process().done(function () {
          data.submit();
        });
        $btn.prop("disabled", true);
      },
      always: function () {
        $btn.prop("disabled", false);
      },
      done: function(e, data) {
        $input.val(JSON.stringify(data.result));
        $input.trigger('js-change');
        $msg.html('<strong>Current: </strong>' + data.result.metadata.filename);
        $deleteButton.removeClass('d-none');
      },
      fail: function() {
        $msg.html('<span class="text-danger">Error uploading file</span>');
      },
      progress: function (e, data) {
        // could show progress bar
        var progress = parseInt(data.loaded / data.total * 100, 10);
        $msg.html('<strong>Uploading: </strong>' + progress + '%');
      }
    }).prop(
      'disabled', !$.support.fileInput
    ).parent().addClass($.support.fileInput ? undefined : 'disabled');

  });
});

$(function() {

  $('.j-mask-input-open').click(function(evt) {
    evt.preventDefault();
    var $toggle = $(this);
    var $root = $toggle.closest('.j-mask-input');

    var $content = $root.find('.j-mask-modal-content');
    window.modalApi.setState({
      contentHtml: $content.html(),
      contentTitle: $toggle.data('title'),
      status: 'open',
      dialogSize: 'md',
    });

    var $modal = $('#j-page-modal-ctl');
    $modal.one('shown.bs.modal', function () {
      var $tmpCtl = $modal.find('.j-mask-input-tmp');
      var $confirmCtl = $modal.find('.j-mask-input-confirm');
      $tmpCtl.on('input', function() {
        $confirmCtl.prop('disabled', !$tmpCtl.val())
      });
      var $realCtl = $root.find('.j-mask-input-real');
      $confirmCtl.click(function() {
        $realCtl.prop('disabled', null);
        $realCtl.val( $tmpCtl.val() )
        $realCtl.trigger('js-change');
        window.modalApi.close();
      });
    });
  });
})

$(function() {
  $('.j-show-more-toggle').click(function() {
    var $showMoreContent = $(this).closest('.show-more__content'),
        maxHeight = $showMoreContent[0].scrollHeight,
        buttonText = 'Show More',
        $showMore = $(this).closest('.j-show-more');
    $showMore.toggleClass('collapsed');
    $showMoreContent.css('max-height', maxHeight); // Prevents transition delay
    $(this).toggleClass('toggled');
    if(!$showMore.hasClass('collapsed')) {
      buttonText = 'Show Less';
    }
    $(this).text(buttonText).blur();
  });
})

$(function() {
  //$('.j-input-percent').mask('##0,00%', {reverse: true});
})

$(function() {
  $('.j-choose-q-row').each(function() {
    var $select = $(this);
    var options = $select.data('options');
    var $outer = $select.closest('.j-q-rows')
    var updateOptions = function() {
      var html = '<option>-- Choose</option>';
      var taken = {};
      $outer.find('.j-q-row:visible').each(function() {
        taken[$(this).attr('id')] = true;
      })
      options.forEach(function(opt) {
        if (!taken[opt.id]) {
          html += '<option value=' + opt.id +'>' + opt.name + '</option>'
        }
      })
      $select.html(html);
    };

    updateOptions();
    $select.on('change', function() {
      var $input = $('#' + $select.val());
      $input.removeClass('d-none');
      $input.find('input').focus();
      updateOptions();
    });

    $outer.find('.j-clear-q-row').click(function(evt) {
      evt.preventDefault();
      var $p = $(this).closest('.j-q-row');
      $p.addClass('d-none');
      var $input = $p.find('input');
      $input.val(null);
      $input.trigger('js-change');
      updateOptions();
    });
  })

});

$(function() {
  $(document).on('change', "input[type='file']", function() {
    var fileName = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
    $('.file-label').val(fileName);
    $('.helper-text').addClass('visible');
  })

  $('.js-animated-answer').on(
    'webkitAnimationEnd oanimationend msAnimationEnd animationend',
    function() {
      $(this).removeClass('updated')
    });

});

$(function() {
  $(document).on('click', '.j-reload-page', function() {
    window.location.reload();
  })
});
