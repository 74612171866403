import Rails from '@rails/ujs';
import wysiwyg from "./wysiwyg"
// blueimp for site app file upload
import "blueimp-file-upload"
import svg4everybody from "svg4everybody"
import setupFlatpicker from "./setupFlatpicker"
import select2SimpleForm from "./select2SimpleForm"
import "./bootstrap"
import "./navigation"
import "./ajaxQueue"
import "./forms"
import "./autosaveForms"
import "./modal"
import "./drpTables"
import "./ajaxHandling"
import "./googleMaps"
import "./onbeforeunload"

window.loadDrp = () => {
  return import(/* webpackChunkName: "drpviz" */ "DrpViz/index")
}

const setup = function () {
  Rails.start()
  window.Rails = Rails
  $(function () {
    const setupSelect2 = select2SimpleForm()
    const setupWysiwyg = wysiwyg()
    svg4everybody()
    const $doc = $(document)
    setupWysiwyg($doc)
    setupFlatpicker($doc)
    setupSelect2.init($doc)
    $doc.on("modal:content-changed", function () {
      const $content = $(this)
      setupWysiwyg($content)
      setupFlatpicker($content)
      setupSelect2.init(this)
    })

    const $visit = $(".j-post-on-load-form")
    if ($visit.length) {
      $.post($visit.attr("action"), $visit.serialize())
    }
  })
}
setup()
