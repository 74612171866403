import { ComponentType, lazy } from "react"

export const retryLoader = <T>(
  lazyComponent: () => Promise<T>,
  attemptsLeft = 1,
  interval = 1000
) => {
  return new Promise<T>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        if (attemptsLeft > 0) {
          setTimeout(() => {
            retryLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
          }, interval)
        } else {
          reject(error)
        }
      })
  })
}

type Lazy = { default: ComponentType<any> }
export const reactRetryLazy = <T extends Lazy>(loader: () => Promise<T>) => {
  return lazy(() => retryLoader(loader))
}
