import React, { useCallback, useMemo } from "react"
import { SrgSiteManagerState } from "../types"
import { StateProvider, useStateValue } from "../state"
import SiteList from "./SiteList"
import SiteSearch, { SiteSearchProps } from "SiteSearch/SiteSearch"

const WrappedSiteSearch = () => {
  const [{ siteQueryUrl, sites }, dispatch] = useStateValue()

  const handleSelectSite: SiteSearchProps["handleSelectSite"] = useCallback(
    (site) => {
      dispatch({ type: "SITE_CREATED", data: { site } })
    },
    [dispatch]
  )

  const selectedSiteIds = useMemo(() => sites.map((c) => c.site.id), [sites])

  return (
    <>
      {siteQueryUrl && (
        <SiteSearch
          title="Add a site to this reporting group"
          siteQueryUrl={siteQueryUrl}
          selectedSiteIds={selectedSiteIds}
          handleSelectSite={handleSelectSite}
          params={{ primary_only: '1' }}
        />
      )}
    </>
  )
}

const App = (props: SrgSiteManagerState) => {
  return (
    <StateProvider initialState={props}>
      <SiteList />
      {props.siteQueryUrl && <WrappedSiteSearch />}
    </StateProvider>
  )
}
export default App
