import React, { memo } from 'react';
import { useStateValue } from '../../state';
import { SrgSite } from '../../types';

const SiteArtifact = (
  props: {
    site: SrgSite,
    rowPrefix: string
  }
) =>{
  const {site, rowPrefix} = props
  const [, dispatch] = useStateValue();

  const handleRestore = () => {
    dispatch({ type: 'SITE_RESTORED', data: { site: site.site } })
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="container">
        <div className='row'>
          <div className='col-md-4'>
            { site.site.id && <input type="hidden" name={rowPrefix + "[id]"} value={site.site.id} /> }
            { site.site.id && <input type="hidden" name={rowPrefix + "[_destroy]"} value='1' /> }
            <div className="mb-0">
              <div>
                <span className="text-id-value-pfx">Site ID</span>
                <span className="text-id-value">{site.site.id}</span>
              </div>
              <h3 className="mb-0">
                <a href={site.site.url} className="text-color-black" target="ror_site">{site.site.name}</a>
              </h3>
            </div>
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <strong>
              <em className="mr-2">Site will be removed from this site reporting group</em>
            </strong>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-link btn-xs"
        onClick={handleRestore}
      >Cancel</button>
    </div>
  )
}

export default memo(SiteArtifact)
