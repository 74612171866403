import { createContext } from "react"
import { AppStore } from "./types"

const initialValue = [
  {
    config: {
      user: { id: "", isAdmin: false, affiliates: [] },
      graphQLUri: "/placeholder",
      routeBasename: "/placeholder",
      recaptchaKey: "",
      uploadPresignUri: "/placeholder"
    }
  },
  (v: any) => {
    throw String(v)
  }
] as [AppStore, (v: AppStore) => void]
const AppConfigContext = createContext<[AppStore, (value: AppStore) => void]>(initialValue)
export default AppConfigContext
