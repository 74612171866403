var onbeforeunloadcbs = []
window.registerBeforeUnload = function (cb) {
  onbeforeunloadcbs.push(cb)
}
window.onbeforeunload = function () {
  var i, str
  for (i = 0; i < onbeforeunloadcbs.length; i++) {
    str = onbeforeunloadcbs[i]()
    if (str) {
      return str
    }
  }
}
