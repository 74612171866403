import React, { Component } from 'react'
import SurveyContext from 'SiteSurveyDemographics/SurveyContext'

let idCounter = 0
type Props = {
  onChange: (evt: React.SyntheticEvent<{value: string}>) => void
  label?: string
  errors?: string[]
  answerField: string
  answerValue: string
  options: {
    id: string
    label: string
  }[]
}
export default class MultipleChoice extends Component<Props, {}> {
  static contextType = SurveyContext
  context!: React.ContextType<typeof SurveyContext>

  formId: number | undefined
  UNSAFE_componentWillMount() {
    idCounter +=1
    this.formId = idCounter
  }

  renderErrors() {
    const {errors} = this.props
    if (errors) {
      return errors.map(
        (msg) =><div key={msg} className="text-danger">{msg}</div>
      )
    }
  }

  render() {
    const {displayType} = this.context
    switch (displayType) {
      case 'input':
        return this.renderControl()
      case 'web':
        return this.renderView()
      case 'print':
        return this.renderPrint()
    }
  }

  renderPrint() {
    const {label, options, answerValue} = this.props
    return <div className='mb-4'>
      { label &&
        <div className='mb-3'>{label}</div>
      }
      <div className="sap-node__content">
        {options.map( (opt, idx) => {
          const selected = opt.id == answerValue
          return <div key={idx} className={'survey-result-check-list__item ' + (selected ? 'chosen' : '')}>

            <div className="survey-result-check-list__icon"></div>
            <div className="survey-result-check-list__label">{opt.label}</div>
          </div>
        })}
      </div>
    </div>
  }

  renderView() {
    const {label, options, answerValue} = this.props
    return <div className='ml-2'>
      { label &&
        <div>{label}</div>
      }
      {options.map( (opt, idx) => {
        const selected = opt.id == answerValue
        return <div key={idx} className={'my-2 ' + (selected ? '' :'text-muted')}>
          <div aria-label={selected ? 'Yes' : 'No'} className='d-inline-block' style={{width: '20px'}}> { selected ? '✔' : ''} </div>
          <span>{opt.label}</span>
        </div>
      })}
    </div>
  }

  renderControl() {
    const {label, options, answerValue, onChange, answerField} = this.props
    return <div className="form-group">
      <div className="form-text">
        {label ? label : ''}
        {this.renderErrors()}
      </div>
      <input type="hidden" name={answerField} value={answerValue} />
      {options.map( (opt, idx) => {
        const htmlId = `survey-mc-radio-${this.formId}.${idx}`
        return <div key={idx} className="form-check">
          <input
            name={answerField}
            type="radio"
            value={opt.id}
            className="form-check-input"
            id={htmlId}
            checked={answerValue == opt.id}
            onChange={onChange}
          />
          <label htmlFor={htmlId}>{opt.label}</label>
        </div>
      })}
    </div>
  }
}
