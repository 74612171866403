const initGooglePlaceSearch = function () {
  $(document).on("keydown", ".j-gp-control", function (e) {
    if (e.which == 13) {
      e.preventDefault()
    }
  })

  $(document).on("focus click", ".j-gp-name", function (evt) {
    evt.stopImmediatePropagation()
    var $root = $(this).closest(".j-gp-picker")
    $root.addClass("open")

    var $control = $root.find(".j-gp-control")
    $control.focus()
    $control.one("blur", function () {
      $root.removeClass("open")
    })

    var autocomplete = new google.maps.places.Autocomplete($control.get(0), {
      types: ["(regions)"]
    })

    var listener = autocomplete.addListener("place_changed", function () {
      var place = autocomplete.getPlace()
      var comps = []

      var excludeComps = { political: true }
      if (place.types && place.types.indexOf("administrative_area_level_2") < 0) {
        excludeComps.administrative_area_level_2 = true
      }
      if (place.address_components) {
        place.address_components.forEach(function (comp) {
          comp.types.forEach(function (type) {
            if (!excludeComps[type]) {
              comps.push(type + ":" + comp.short_name)
            }
          })
        })
      }
      if (comps) {
        $root.find(".j-gp-name").val(place.formatted_address)
        $root.find(".j-gp-result").val(comps.join("|"))
        $root.find(".j-gp-google-id").val(place.place_id)
      }
      $control.val("")
      google.maps.event.removeListener(listener)

      $root.removeClass("open")
    })
  })
}

export default initGooglePlaceSearch
