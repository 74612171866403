import React, { createContext, useContext, useReducer } from 'react';
import { Contact, ContactAction, ContactManagerState } from './types';
import { Site } from '../SiteSearch/types'

const initializeContact = (site: Site, state: ContactManagerState): Contact => {
  const {user, contacts} = state
  // contact is confirmed if user has admin rights or if contact
  // is already confirmed within the site family
  const status = (
    !user.id || // creating a new user
    site.permission.admin ||
    (user.confirmed_site_ids.indexOf(site.id) >= 0)
  ) ? 'confirmed' : 'pending'

  const familyContact = contacts.find(
    (c) => c.site.family_id === site.family_id
  )

  return {
    id: undefined,
    site,
    status,
    role_id: familyContact ? familyContact.role_id : site.default_role_id,
    primary_contact: false,
    secondary_contact: false,
    deleted: false,
  }
}

const addSiteContact = (state: ContactManagerState, site: Site): ContactManagerState => {
  const found = state.contacts.find( contact => contact.site.id == site.id )

  const newContact = found
    ? {...found, deleted: false}
    : initializeContact(site, state)

  return {
    ...state,
    modified: true,
    contacts: state.contacts.filter((contact) => contact != found).concat(newContact)
  }
}

const updateSiteContact = (
  state: ContactManagerState,
  site: Site,
  updates: Partial<Contact>
): ContactManagerState => {
  return {
    ...state,
    modified: true,
    contacts: state.contacts.map(
      (contact) => (
        contact.site.id === site.id
          ? { ...contact, ...updates}
          : contact
      )
    )
  }
}

const contactReducer = (
  state: ContactManagerState, action: ContactAction
): ContactManagerState => {
  const {site} = action.data
  switch (action.type) {
    case 'CONTACT_CREATED':
      return addSiteContact(state, site)
    case 'CONTACT_REMOVED':
      return updateSiteContact(state, site, {deleted: true})
    case 'CONTACT_RESTORED':
      return updateSiteContact(state, site, {deleted: false})
    case 'CONTACT_CONFIRMED':
      return updateSiteContact(state, site, {status: 'confirmed'})
    case 'CONTACT_ROLE_UPDATED':
      return updateSiteContact(state, site, {role_id: action.data.role.id})
    case 'CONTACT_PC_UPDATED':
      return updateSiteContact(state, site, {primary_contact: action.data.value, secondary_contact: false})
    case 'CONTACT_SC_UPDATED':
      return updateSiteContact(state, site, {secondary_contact: action.data.value})
    default:
      return state
  }
}

type context = [ContactManagerState, React.Dispatch<ContactAction>]
const fakeState:ContactManagerState = {
  contacts: [],
  roles: [],
  formPrefix: '',
  siteQueryUrl: null,
  user: {name: '', confirmed_site_ids: []}
}
export const StateContext = createContext<context>([fakeState, () => undefined]);
export const StateProvider = (props: {
  initialState: ContactManagerState
  children: any
}) => {
  const {initialState, children} = props
  return (
    <StateContext.Provider
      value={ useReducer(contactReducer, initialState) }
    >
      {children}
    </StateContext.Provider>
  )
}
export const useStateValue = () => useContext(StateContext);
