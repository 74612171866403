import React, { FC } from "react"
import useUniqueHtmlId from "Content/Labeled/useUniqueHtmlId"

// Deprecate for material ui
const ContentLabeledRadioTag: FC<{
  label: string
  options: { id: string; label: string }[]
  value: string | null
  onChange: (evt: React.FormEvent<HTMLInputElement>) => void
  name: string
}> = ({ label, options, value, onChange, name }) => {
  const htmlId = useUniqueHtmlId("radio")
  return (
    <div className="form-group">
      <legend className="col-form-label pt-0">{label}</legend>
      <input type="hidden" name={name} value={value ? value : undefined} />
      <div className="d-flex">
        {options.map((opt, idx) => {
          return (
            <div key={idx} className="form-check mr-4">
              <input
                name={name}
                type="radio"
                value={opt.id}
                className="form-check-input"
                id={`${htmlId}.${idx}`}
                checked={value === opt.id}
                onChange={onChange}
              />
              <label htmlFor={`${htmlId}.${idx}`}>{opt.label}</label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentLabeledRadioTag
