import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStateValue } from '../../state';
import SiteContactArtifact from './SiteContactArtifact';
import SiteContactForm from './SiteContactForm';
import SiteContactView from './SiteContactView';

const duration = 500
const SiteContactList = () => {
  const [{ modified, contacts, formPrefix, originSite, addOriginSiteByDefault}, dispatch] = useStateValue();
  useEffect(
    () => {
      if (modified) {
        //@ts-ignore
        window.modalApi ? window.modalApi.setConfirmClose(true) : null
      }
    },
    [modified]
  )
  useEffect(
    () => {
      if (originSite) {
        const hasContact = contacts.some((contact) => {
          if (addOriginSiteByDefault) {
            return contact.site.id === originSite.id
          } else {
            return contact.site.family_id === originSite.family_id
          }
        })
        if (!hasContact) {
          dispatch({ type: 'CONTACT_CREATED', data: {site: originSite} })
        }
      }
    }, [originSite, addOriginSiteByDefault, contacts, dispatch]
  )

  return (
    <ul className="card-list dash__card mb-4">
      <TransitionGroup component={null}>{
        contacts.map(
          (contact, idx) => {
            const rowPrefix = `${formPrefix}[${idx}]`
            const { site } = contact
            if (site.permission.admin || site.permission.manage) {
              if (contact.deleted) {
                return (
                  <CSSTransition key={site.id + 'artifact'} timeout={duration} classNames="artifact">{
                    () => (
                      <li className="s2-card-tx contact-card list-item">
                        <SiteContactArtifact
                          contact={contact}
                          rowPrefix={rowPrefix}
                        />
                      </li>
                    )
                  }</CSSTransition>
                )
              } else {
                return (
                  <CSSTransition key={site.id + 'form'} timeout={duration} classNames={contact.id ? 'shrink' : 'slide'}>{
                      <li className="s2-card-tx contact-card list-item">
                        <SiteContactForm
                          contact={contact}
                          rowPrefix={rowPrefix}
                        />
                      </li>
                  }</CSSTransition>
                )
              }
            } else {
              return (
                <CSSTransition key={site.id} timeout={0}>
                  <li className='contact-card list-item d-block'>
                    <SiteContactView
                      contact={contact}
                    />
                  </li>
                </CSSTransition>
              )
            }
          }
        )
      }</TransitionGroup>
    </ul>
  )
}
export default SiteContactList
