import React, { useMemo, useRef } from "react"
import { Popper as MuiPopper } from "@mui/material"
import { Site } from "./types"
import SearchResultItem from "./SearchResultItem"

const menuStyle = {
  width: "400px",
  zIndex: 99999,
  boxShadow: "1px 1px #ccc",
  backgroundColor: "#fff",
  overflowY: "auto" as "auto", //prevent widening
  maxHeight: "300px"
}
const SearchResults = (props: {
  selectedSiteIds: number[]
  onChoose: (site: Site) => void
  loading: boolean
  error: boolean
  results: Site[]
}) => {
  const { selectedSiteIds, onChoose, results, loading, error } = props
  const chosenSiteIds = useMemo(() => new Set(selectedSiteIds), [selectedSiteIds])
  const elemRef = useRef<HTMLDivElement | null>(null)

  const items = results.map((site: Site) => (
    <SearchResultItem
      key={site.id}
      site={site}
      onChoose={onChoose}
      chosen={chosenSiteIds.has(site.id)}
    />
  ))

  const resultsContent = (
    <MuiPopper
      open
      placement="bottom-start"
      anchorEl={elemRef.current}
      sx={{ zIndex: 1200 }}
      modifiers={[
        { name: "flip", enabled: false },
        { name: "preventOverflow", options: { escapeWithReference: true } }
      ]}
    >
      {
        <div style={menuStyle}>
          {error && (
            <a className="dropdown-item disabled text-danger" href="#">
              An error occurred while connecting to the server
            </a>
          )}
          {loading && !loading && (
            <a className="dropdown-item disabled" href="#">
              Loading...
            </a>
          )}
          {!loading && !error && items.length == 0 && (
            <a className="dropdown-item disabled" href="#">
              No sites found
            </a>
          )}
          {!loading && items}
        </div>
      }
    </MuiPopper>
  )

  return (
    <>
      <div ref={elemRef}></div>
      {resultsContent}
    </>
  )
}
export default React.memo(SearchResults)
