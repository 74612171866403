import initGooglePlaceSearch from "./googlePlaceSearch"
import initGoogleMapControl from "./googleMapControl"

const initGoogleMaps = function () {
  const promise = new Promise((resolve) => {
    window.handleGoogleMapsLoad = function () {
      $(function () {
        initGoogleMapControl()
        initGooglePlaceSearch()
        resolve()
      })
    }
  })

  const { googleApiKey } = window
  const selector = ".j-map-control,.j-map-check,.j-gp-control"

  let loaded = false
  const loadMaps = () => {
    if (loaded) {
      return
    }
    loaded = true
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=handleGoogleMapsLoad`
    script.defer = true
    document.head.appendChild(script)
  }

  // load if we can infer the page needs google
  if (googleApiKey && document.querySelectorAll(selector).length) {
    loadMaps()
  }

  // force load
  window.ensureGoogleMaps = () => {
    loadMaps()
    return promise
  }
}

initGoogleMaps()
