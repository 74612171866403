import { ErrorInfo } from "react"

const sentry = window.Sentry

const logToSentry = (error: Error, errorInfo?: ErrorInfo) => {
  if (!sentry) {
    console.error(error)
    return
  }
  sentry.withScope(function (scope) {
    if (errorInfo) {
      scope.setExtra("errorInfo", JSON.stringify(errorInfo))
    }
    sentry.captureException(error)
  })
}

export default logToSentry
