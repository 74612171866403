import React, { Component } from 'react'
import { ReactNodeLike } from 'prop-types';
import { Site, ValidationCondition } from '../types';
import SurveyContext from '../SurveyContext'

type Props = {
  site: Site
  dataTable: ReactNodeLike
  totalValue: number
  collapsed: boolean
  validated: boolean
  validationCondition: ValidationCondition
}

export default class CollapsableSiteSection extends Component<Props, { collapsed: boolean }> {

  static contextType = SurveyContext
  context!: React.ContextType<typeof SurveyContext>;

  constructor(props: Props) {
    super(props)
    this.state = {
      collapsed: props.collapsed,
    }
  }

  render() {
    const { collapsed } = this.state
    const { site, dataTable } = this.props
    const {displayType} = this.context

    if (displayType === 'print') {
      return <div className="my-4">
        <div className='mb-2'>
          <h5>
            {site.type == 'primary' ? 'Primary Site' : 'Satellite Site'}
            {': '}
            {site.ident}
            {' - '}
            {site.name}
          </h5>
        </div>
        {dataTable}
      </div>
    }

    return (
      <div className={"str1-item " + (collapsed ? 'collapsed' : 'expanded')}>
        <a className="str1-item__head d-block" href="#" onClick={this.handleToggleCollapsed}>
          <div className='d-flex'>
            <div className='mr-2' style={{flexGrow: 1}}>
            <h3>{site.type == 'primary' ? 'Primary Site' : 'Satellite Site'} {site.ident}</h3>
            <h4>{site.name}</h4>
          </div>
          <div className='text-right' style={{flexGrow: 0, minWidth: "120px"}}>
            {this.renderStatus()}
          </div>
        </div>
      </a>
      <div className="str1-item__body">
        {dataTable}
      </div>
    </div >
    )
  }

  renderStatus() {
    const { totalValue, validated, validationCondition} = this.props
    var badge
    if (validationCondition === 'fuzzy') {
      if (totalValue <= 0) {
        badge = <div className="d-block badge badge-danger">Data Needed</div>
      }
      if (totalValue <= 200) {
        badge = <div className="d-block badge badge-danger">Data Invalid</div>
      }
    }
    if (totalValue === 100) {
      badge = <span className="d-block badge badge-success">✔ Complete</span>
    } else if (totalValue < 100) {
      if (validated) {
        badge = <div className="d-block badge badge-danger">Data Needed</div>
      } else {
        badge = <div className="d-block badge badge-warning">Data Needed</div>
      }
    } else if (totalValue > 100) {
      badge = <div className="d-block badge badge-danger">Data Invalid</div>
    }

    if (badge) {
      return <div style={{fontSize: "1.2em"}}>{badge}</div>
    }

  }

  handleToggleCollapsed = (evt:React.SyntheticEvent) => {
    evt.preventDefault()
    this.setState(({collapsed}) => ({collapsed: !collapsed}))
  }
}
