import { useCallback, useContext, useMemo } from "react"
import AppConfigContext from "./Context"
import { StatusMessage } from "./types"

export const useCurrentRouteBasename = () => useContext(AppConfigContext)[0]?.config.routeBasename

export const useRecaptchaKey = () => useContext(AppConfigContext)[0]?.config.recaptchaKey

export const useCurrentUser = () => useContext(AppConfigContext)[0]?.config.user
export const useUploadPresignUri = () => useContext(AppConfigContext)[0]?.config.uploadPresignUri || '/presign'

export const useCurrentStatusMessage = () => {
  const [state, setState] = useContext(AppConfigContext)
  const setMessage = useCallback(
    (statusMessage: StatusMessage | undefined) => {
      setState({ ...state, statusMessage })
    },
    [setState, state]
  )
  return useMemo(
    () => [state.statusMessage, setMessage] as const,
    [state.statusMessage, setMessage]
  )
}
