import { Button, Dialog, LinearProgress, Paper } from "@mui/material"
import { makeStyles, CSSObject } from "Theme/makeStyles"
import { ErrorOutlineTwoTone as ErrorIcon } from "@mui/icons-material"
import { useCurrentUser } from "AppConfig/hooks"
import React, { useEffect } from "react"

const useStyles = makeStyles()(({ typography, spacing }) => ({
  root: {
    display: "flex",
    padding: spacing(3),
    marginTop: spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500
  },
  text: {
    marginBottom: spacing(3)
  },
  icon: {
    marginRight: spacing(2),
    flexGrow: 0
  },
  message: {
    ...(typography.h6 as CSSObject),
    flexGrow: 1
  }
}))

const Wrapper: React.FC<{ inline: boolean; className: string }> = ({
  children,
  inline,
  className
}) => {
  if (inline) {
    return (
      <Paper className={className} variant="outlined">
        {children}
      </Paper>
    )
  }
  return (
    <Dialog open>
      <div className={className}>{children}</div>
    </Dialog>
  )
}

const messages = {
  default:
    "We're sorry but something went wrong. We've been notified about this issue and we'll take a look at it shortly.",
  notAuthorized: "Sorry, you don't have permission to access this page",
  notFound: "Sorry, the page could not be found",
  conflict:
    "We detected a conflict while trying to save your changes. This may happen if you have the page open in several tabs at once. Please reload the page and check to make sure the data you entered is correct.",
  networkProblem:
    "Sorry, there was a network error connecting to the server. Please check your internet connection"
}

export type ErrorDialogType =
  | "notFound"
  | "notAuthorized"
  | "networkProblem"
  | "conflict"
  | "default"
interface Props {
  variant?: ErrorDialogType
  inline?: boolean
}
const EnvironmentErrorDialog: React.FC<Props> = ({ variant = "default", inline = false }) => {
  const { classes } = useStyles()
  const user = useCurrentUser()
  const goToLogin = !user.id && variant === "notAuthorized"
  useEffect(() => {
    if (goToLogin) {
      const params = new URLSearchParams({
        return_to: window.location.pathname
      })
      window.location.href = `/login?${params.toString()}`
    }
  }, [goToLogin])

  if (goToLogin) {
    return (
      <Wrapper key="redir" className={classes.root} inline={inline}>
        <div>
          <div className={classes.message}>Loading login page &hellip;</div>
          <LinearProgress variant="indeterminate" />
        </div>
      </Wrapper>
    )
  }

  const tryReload = variant === "networkProblem" || variant === "default"
  return (
    <Wrapper key="error" className={classes.root} inline={inline}>
      <div className={classes.icon}>
        <ErrorIcon fontSize="large" />
      </div>
      <div className={classes.message}>
        <div className={classes.text}>{messages[variant]}</div>
        <div className="text-right">
          {!tryReload && (
            <Button variant="contained" color="primary" component={"a"} href="/">
              Continue
            </Button>
          )}
          {tryReload && (
            <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
              Reload
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  )
}
export default EnvironmentErrorDialog
