import EnvironmentErrorHandler from "Environment/ErrorHandler"
import { polyfill } from "Environment/polyfill"
import { reactRetryLazy } from "Environment/retryLoader"
import { createElement, Suspense } from "react"
import ReactDOM from "react-dom"

const SiteContactManager = reactRetryLazy(
  () => import(/* webpackMode: "eager" */ "SiteContactManager")
)
const SrgSiteManager = reactRetryLazy(
  () => import(/* webpackMode: "eager" */ "SrgSiteManager")
)
const SiteSurveyDemographics = reactRetryLazy(
  () => import(/* webpackMode: "eager" */ "SiteSurveyDemographics")
)
const HealthSystemSiteRequest = reactRetryLazy(
  () => import(/* webpackMode: "eager" */ "HealthSystemSiteRequest")
)
const SqcChartDashboard = reactRetryLazy(
  () => import(/* webpackChunkName: "SqcCharts" */ "SqcChartDashboard")
)
const HealthSystemCrm = reactRetryLazy(() => import(/* webpackChunkName: "HCS" */ "HcsCrm"))
const SrmSiteReportApp = reactRetryLazy(
  () => import(/* webpackChunkName: "SrmSiteReportApp" */ "Srm/SiteReport/App")
)
const SrmSiteSearch = reactRetryLazy(
  () => import(/* webpackChunkName: "SrmOmniSearch" */ "Srm/OmniSearch/App")
)
const SrmOnboardingApp = reactRetryLazy(
  () => import(/* webpackChunkName: "SrmSiteReportApp" */ "Srm/Onboarding/App")
)
const RlbApplication = reactRetryLazy(() => import(/* webpackChunkName: "RLB" */ "Rlb/Application"))
const WebAssets = reactRetryLazy(() => import(/* webpackChunkName: "AdminAssets" */ "WebAssets/Application"))

type Lazyness = Record<string, React.LazyExoticComponent<any>>
const rootComponents: Lazyness = {
  SiteContactManager,
  SrgSiteManager,
  SqcChartDashboard,
  HealthSystemCrm,
  ResourceLibrary: RlbApplication,
  SrmSiteReportApp,
  SrmSiteSearch,
  SrmOnboardingApp,
  SiteSurveyDemographics,
  HealthSystemSiteRequest,
  WebAssets,
}

const Loading: React.FC = () => {
  return (
    <div className="pgldr">
      <div aria-busy="true" aria-live="polite" className="pgldr-inner">
        Loading …
      </div>
    </div>
  )
}

const renderSimpleError = (root: HTMLElement) => {
  root.innerHTML =
    "<div style='margin: 30px auto; max-width: 400px; font-size: 16px'>We're sorry but something went wrong. We've been notified about this issue and we'll take a look at it shortly.</div>"
}

window.renderRorComponent = (root, compName, props) => {
  if (rootComponents[compName]) {
    polyfill()
      .then(() => {
        ReactDOM.render(
          <EnvironmentErrorHandler inline={compName !== "SrmSiteSearch"}>
            <Suspense fallback={<Loading />}>
              {createElement(rootComponents[compName], props)}
            </Suspense>
          </EnvironmentErrorHandler>,
          root
        )
      })
      .catch((e) => {
        renderSimpleError(root)
        throw e
      })
  } else {
    renderSimpleError(root)
    throw new Error(`unknown component type "${compName}"`)
  }
}
