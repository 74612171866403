import clsx from "clsx"
import React, { FC } from "react"
import useUniqueHtmlId from "Content/Labeled/useUniqueHtmlId"

// Deprecate for material ui
const ContentLabeledTextTag: FC<{
  label: string
  value: string | null
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  name?: string
  errors?: string[]
  disabled?: boolean
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"]
  required?: boolean
}> = ({ label, type = "text", required = false, value, onChange, name, disabled, errors = [] }) => {
  const invalid = errors.length > 0
  const htmlId = useUniqueHtmlId("text")

  return (
    <div className="form-group">
      <label htmlFor={htmlId}>{label}</label>
      <input
        type={type}
        className={clsx("form-control", invalid && "is-invalid")}
        id={htmlId}
        value={value ? value : ""}
        disabled={!!disabled}
        name={name}
        onChange={onChange}
        required={required}
      />
      {errors.map((msg) => (
        <div key={msg} className="invalid-feedback">
          {msg}
        </div>
      ))}
    </div>
  )
}

export default ContentLabeledTextTag
