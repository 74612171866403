import ContentLabeledBooleanTag from "Content/Labeled/LabeledBooleanTag"
import ContentLabeledSelectTag, { SelectOption } from "Content/Labeled/LabeledSelectTag"
import ContentLabeledTextTag from "Content/Labeled/LabeledTextTag"
import React, { FC } from "react"
import { HealthSystemRequest } from "./types"

const Form: FC<{
  request: HealthSystemRequest
  onChange: (v: Partial<HealthSystemRequest>) => void
  formFieldName: (v: keyof HealthSystemRequest) => string
  healthSystems: SelectOption[]
}> = ({ onChange, request, formFieldName, healthSystems }) => {
  return (
    <>
      <input type="hidden" name={formFieldName("requestType")} value={request.requestType} />
      <ContentLabeledSelectTag
        label="Choose a Health System"
        options={healthSystems}
        name={formFieldName("healthSystemId")}
        value={request.healthSystemId}
        onChange={(evt) => onChange({ healthSystemId: evt.currentTarget.value })}
        disabled={request.requestType !== "join"}
      />
      <ContentLabeledBooleanTag
        label="My Health System is not in the list above"
        checked={request.requestType === "new"}
        onChange={() => {
          onChange({
            requestType: request.requestType === "new" ? "join" : "new"
          })
        }}
      />

      {request.requestType === "new" && (
        <div className="mt-3">
          <h3 className="mb-3">Tell us about your health system</h3>
          <fieldset>
            <div className="form-row">
              <div className="col-lg-6">
                <ContentLabeledTextTag
                  label="What's the name of your health system?"
                  name={formFieldName("newHealthSystemName")}
                  value={request.newHealthSystemName}
                  required
                  onChange={(evt) => onChange({ newHealthSystemName: evt.currentTarget.value })}
                />
                <ContentLabeledTextTag
                  label="Website URL"
                  type="url"
                  name={formFieldName("newHealthSystemWebsiteUrl")}
                  value={request.newHealthSystemWebsiteUrl}
                  onChange={(evt) =>
                    onChange({
                      newHealthSystemWebsiteUrl: evt.currentTarget.value
                    })
                  }
                />
              </div>
            </div>
          </fieldset>
        </div>
      )}
    </>
  )
}
export default Form
