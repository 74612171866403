import "flatpickr"

export default function ($root) {
  $root.find(".j-datepicker-input").each(function () {
    var $e = $(this)
    var opts = {}
    if ($e.data("date-mode")) {
      opts.mode = "range"
    }
    $e.flatpickr(opts)
  })
}
