import React, { memo } from 'react';
import { useStateValue } from 'SiteContactManager/state';
import { Contact } from '../../types';

const SiteContactArtifact = (
  props: {
    contact: Contact,
    rowPrefix: string
  }
) =>{
  const {contact, rowPrefix} = props
  const site = contact.site
  const [, dispatch] = useStateValue();

  const handleRestore = () => {
    dispatch({ type: 'CONTACT_RESTORED', data: {site} })
  }

  return (
    <div>
      { contact.id && <input type="hidden" name={rowPrefix + "[id]"} value={contact.id} /> }
      { contact.id && <input type="hidden" name={rowPrefix + "[_destroy]"} value='1' /> }
      { contact.id && <input type="hidden" name={rowPrefix + "[site_id]"} value={site.id} /> }

      <div className="text-leader">{site.ident}: {site.name}</div>
      <strong>
        <em className="mr-2">Contact will be removed from this site</em>
      </strong>
      <button
        type="button"
        className="btn btn-link btn-xs"
        onClick={handleRestore}
      >Cancel</button>
    </div>
  )
}

export default memo(SiteContactArtifact)
