import React, { useCallback, useMemo } from "react"
import { ContactManagerState } from "SiteContactManager/types"
import { StateProvider, useStateValue } from "../state"
import SiteContactList from "./SiteContactList"
import SiteSearch, { SiteSearchProps } from "SiteSearch/SiteSearch"

const WrappedSiteSearch = () => {
  const [{ siteQueryUrl, contacts }, dispatch] = useStateValue()

  const handleSelectSite: SiteSearchProps["handleSelectSite"] = useCallback(
    (site) => {
      dispatch({ type: "CONTACT_CREATED", data: { site } })
    },
    [dispatch]
  )

  const selectedSiteIds = useMemo(() => contacts.map((c) => c.site.id), [contacts])

  return (
    <>
      {siteQueryUrl && (
        <SiteSearch
          title="Add this contact to another site"
          siteQueryUrl={siteQueryUrl}
          selectedSiteIds={selectedSiteIds}
          handleSelectSite={handleSelectSite}
        />
      )}
    </>
  )
}

const App = (props: ContactManagerState) => {
  return (
    <StateProvider initialState={props}>
      <SiteContactList />
      {props.siteQueryUrl && <WrappedSiteSearch />}
    </StateProvider>
  )
}
export default App
