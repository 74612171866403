const initGoogleMapControl = function () {
  var defaultCoords = { lat: 37.09024, lng: -95.712891 }
  var placeZooom = 16
  var helpA = "Enter a valid location"
  var helpB = "Enter a location or drag the marker on the map"
  $(document).on("keydown", ".j-place-search", function (e) {
    if (e.which == 13) {
      e.preventDefault()
    }
  })
  var loadMap = function (el, open) {
    var $root = $(el)
    var $lat = $root.find(".j-lat-input")
    var $lng = $root.find(".j-lng-input")
    var $mapBox = $root.find(".j-map")
    var $placeSearch = $root.find(".j-place-search")
    var $help = $root.find(".j-map-help")
    var center = $root.data("coords")
    var mapCenter = center ? center : defaultCoords

    if (open) {
      if (center) {
        $lat.val(center.lat)
        $lng.val(center.lng)
      }
    } else {
      $lat.val(null)
      $lng.val(null)
    }

    $root.find(".j-map-toggle").toggleClass("d-none", !open)
    $placeSearch.toggleClass("d-none", !open)
    $mapBox.toggleClass("d-none", !open)
    var autocomplete = new google.maps.places.Autocomplete($placeSearch.get(0))
    var map = new google.maps.Map($mapBox.get(0), {
      zoom: center ? placeZooom : 4,
      center: mapCenter
    })

    var marker = new google.maps.Marker({
      position: mapCenter,
      map: map,
      draggable: true,
      title: "Site Address",
      visible: !!center
    })

    var locationToForm = function (latLng) {
      $lat.val(latLng.lat())
      $lng.val(latLng.lng())
    }

    autocomplete.addListener("place_changed", function () {
      marker.setVisible(false)
      var place = autocomplete.getPlace()
      if (!place.geometry) {
        $help.text(helpA)
        return
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(placeZooom)
      }
      marker.setPosition(place.geometry.location)
      locationToForm(place.geometry.location)
      marker.setVisible(true)
      $help.text(helpB)
    })

    if (center) {
      $help.text(helpB)
    } else {
      $help.text(helpA)
    }

    google.maps.event.addListener(marker, "dragend", function (evt) {
      locationToForm(evt.latLng)
    })
  }

  var toggleCtl = function () {
    var $ctl = $(this)
    loadMap($ctl.closest(".j-map-control").get(0), $ctl.prop("checked"))
  }

  $(document).on("change", ".j-map-check", toggleCtl)
  $(".j-map-check:checked").each(toggleCtl)
}

export default initGoogleMapControl
