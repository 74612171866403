import MultipleChoice from './MultipleChoice';
import React, { Component } from 'react';
import SiteSection from './SiteSection';
import { Question, Site, Measure, Availability, ValidationCondition } from '../types';
import SurveyContext from 'SiteSurveyDemographics/SurveyContext';


type Props = {
    question: Question
    sites: Site[]
    measures: Measure[]
    validated: boolean
    validationCondition: ValidationCondition
    availability: Availability
  }
type State = {
  availability: Availability
  expanded: boolean,
}
export default class SiteSectionList extends Component<Props, State> {
  static contextType = SurveyContext
  context!: React.ContextType<typeof SurveyContext>

  constructor(props: Props) {
    super(props)
    this.state = {
      availability: Object.assign({}, props.availability),
      expanded: false,
    }
  }

  render() {
    const { expanded } = this.state
    return <div>
      <div>
        {this.renderAvailability()}
      </div>
      <div className={"str1-expandy__content " + (expanded ? 'expanded' : '')}>
        {this.renderSiteSections()}
      </div>
    </div>
  }

  renderAvailability() {
    const { availability } = this.state
    const {displayType} = this.context
    if (availability.options) {
      return <MultipleChoice
        {...availability}
        answerValue={availability.answerValue || ''}
        onChange={this.handleAvailabilityChange}
      />
    } else if (displayType === 'input') {
      return <input
        type="hidden"
        name={availability.answerField}
        value={availability.answerValue}
      />
    }
  }

  renderSiteSections() {
    const { question, sites, measures, validated, validationCondition} = this.props
    const { availability } = this.state

    if (availability.options && !availability.answerValue) {
      // no value chosen yet
      return
    }
    if (availability.answerValue === 'none') {
      return
    }

    var selectedSites
    if (availability.answerValue === 'by_location') {
      selectedSites = sites
    } else {
      selectedSites = sites.length == 1 ? sites : sites.filter((s) => s.type == 'primary')
    }

    const collapsable = selectedSites.length > 1

    return selectedSites.map((site) => {
      const siteMeasures = measures.filter((m) => m.siteId == site.id)

      const locationName = site.type === "primary" ?
        `Primary Site ID ${site.ident}` : `Satellite Site ID ${site.ident}`

      return <SiteSection
        key={site.id}
        collapsable={collapsable}
        question={question}
        locationName={locationName}
        site={site}
        measures={siteMeasures}
        validated={validated}
        validationCondition={validationCondition}
      />
    })
  }

  timeout: number | undefined
  handleAvailabilityChange = (evt: React.SyntheticEvent<{value: string}>) => {
    const answerValue = evt.currentTarget.value
    this.setState(({availability}) => ({
      availability: {
        ...availability,
        answerValue
      },
      expanded: true
    }))
    this.timeout = window.setTimeout(() => this.setState({ expanded: false }), 600)
  }
  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout)
    }
  }

}
