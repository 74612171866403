import ContentLabeledRadioTag from 'Content/Labeled/LabeledRadioTag';
import ContentLabeledSelectTag from 'Content/Labeled/LabeledSelectTag';
import React, { useState, useMemo, useCallback } from 'react';
import { useStateValue } from 'SiteContactManager/state';
import { Contact } from '../../types';
import { Site } from '../../../SiteSearch/types'

const yesAndNo = [
  { id: 'true', label: 'Yes' },
  { id: 'false', label: 'No' }
]

const Pending = (
  { site, handleChange }: {
    site: Site
    handleChange: any
  }
) => {
  if (site.permission.admin) {
    return <div className='text-right'>
      <em className="mr-2">
        This contact is pending approval
        </em>
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={handleChange}
      >Approve this contact</button>
    </div>
  } else {
    return <div>
      <em>Pending approval</em>
      <div className="small">Your Affiliate Leader and National ROR programs staff will be asked to approve this contact before they are officially added to your site</div>
    </div>
  }
}

const SiteContactForm = (
  props: {
    contact: Contact
    rowPrefix: string
  }
) => {
  const { contact, rowPrefix } = props
  const { site } = contact
  const [{ roles, originSite, contacts, user }, dispatch] = useStateValue()
  const [message, setMessage] = useState<string | undefined>()
  const [pcPrompt, setPcPrompt] = useState<string | undefined>()

  const roleErrors = useMemo(
    () => {
      if (contact.primary_contact) {
        const role = roles.find(role => role.id == contact.role_id)
        if (role && !role.is_staff) {
          const staffRoleNames = roles.filter(role => role.is_staff).map(role => role.label)
          return [`The primary contact must be a staff member (${staffRoleNames.join(', ')})`]
        }
      }
      return []
    },
    [contact, roles]
  )

  const handleDestroy = useCallback(
    () => {
      dispatch({ type: 'CONTACT_REMOVED', data: { site } })
    },
    [dispatch, site]
  )

  const handleRoleChange = useCallback(
    (evt: React.SyntheticEvent<{ value: string }>) => {
      const value = Number(evt.currentTarget.value)
      const role = roles.find(r => r.id === value)
      if (role) {
        dispatch({ type: 'CONTACT_ROLE_UPDATED', data: { site, role } })
      } else {
        throw new Error(`unknown role ${value}`)
      }
    },
     [dispatch, roles, site]
  )

  const handlePendingChange = useCallback(
    () => {
      dispatch({ type: 'CONTACT_CONFIRMED', data: { site } })
      setMessage('Contact Approved')
    },
     [dispatch, site]
  )

  const handlePcWithPrompt = useCallback(
    (evt: React.SyntheticEvent<{ value: string }>) => {
      const value = evt.currentTarget.value === 'true'
      if (value && user && site.primary_user && site.primary_user.id !== user.id) {
        setPcPrompt(
          `Are you sure you want to replace ${site.primary_user.name} as the primary contact at this site?`
        )
      } else {
        dispatch({ type: 'CONTACT_PC_UPDATED', data: { site, value } })
      }
    },
     [dispatch, user, site]
  )

  const handlePcChange = useCallback(
    (evt: React.SyntheticEvent<{ value: string }>) => {
      const value = evt.currentTarget.value === 'true'
      dispatch({ type: 'CONTACT_PC_UPDATED', data: { site, value } })
      setPcPrompt(undefined)
    },
    [dispatch, site]
  )

  const handleScChange = useCallback(
    (evt: React.SyntheticEvent<{ value: string }>) => {
      const value = evt.currentTarget.value === 'true'
      dispatch({ type: 'CONTACT_SC_UPDATED', data: { site, value } })
    },
    [dispatch, site]
  )

  const canDestroy = useMemo(
    () => {
      if (originSite && site.family_id === originSite.family_id) {
        const originContacts = contacts.filter(
          contact => (
            !contact.deleted &&
            contact.site.family_id === originSite.family_id
          )
        )
        return originContacts.length > 1
      } else {
        return true
      }
    }, [originSite, site, contacts]
  )

  return (
    <div>
      {contact.id && <input type="hidden" name={rowPrefix + "[id]"} value={contact.id} />}
      <input type="hidden" name={rowPrefix + "[site_id]"} value={site.id} />
      <input type="hidden" name={rowPrefix + "[status]"} value={contact.status} />
      <input type="hidden" name={rowPrefix + "[secondary_contact]"} value="false" />

      <div className="d-flex justify-content-between">
        <div className="mb-2">
          <div>
            <span className="text-id-value-pfx">Site ID</span>
            <span className="text-id-value">{site.ident}</span>
          </div>
          <h3 className="mb-0">
            <a href={site.url} className="text-color-black" target="ror_site">{site.name}</a>
          </h3>
        </div>
        {canDestroy && <div>
          <button
            type="button"
            className="btn btn-link"
            onClick={handleDestroy}
          ><i className="i-remove" /></button>
        </div>
        }
      </div>
      <div className="row">
        <div className="col-md-6">
          <ContentLabeledSelectTag
            name={rowPrefix + "[role_id]"}
            onChange={handleRoleChange}
            label="Role at this site"
            value={contact.role_id ? String(contact.role_id) : null}
            options={roles}
            errors={roleErrors}
          />
        </div>
        <div className="col-md-6">
          <div className="mb-3">{
            pcPrompt
              ? (
                <div>
                  <em className="mr-2">{pcPrompt}</em>
                  <div className="text-right">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={handlePcChange}
                        value="false"
                      >Cancel</button>
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={handlePcChange}
                        value="true"
                      >OK</button>
                    </div>
                  </div>
                </div>
              )
              : <ContentLabeledRadioTag
                name={rowPrefix + "[primary_contact]"}
                onChange={handlePcWithPrompt}
                label="This person is the primary contact for this site"
                value={contact.primary_contact ? 'true' : 'false'}
                options={yesAndNo}
              />
          }</div>
          <div>{!contact.primary_contact &&
            <ContentLabeledRadioTag
              name={rowPrefix + "[secondary_contact]"}
              onChange={handleScChange}
              label="This person is a secondary contact for this site"
              value={contact.secondary_contact ? 'true' : 'false'}
              options={yesAndNo}
            />
          }</div>
        </div>
      </div>
      {contact.status == 'pending' && <Pending handleChange={handlePendingChange} site={site} />}
      {message && <div className="text-right">{message}</div>}
    </div>
  )
}
export default SiteContactForm
