import { useTheme as xUseTheme } from "@mui/material/styles"
import { createMakeAndWithStyles } from "tss-react"

export const { makeStyles, withStyles } = createMakeAndWithStyles({
  useTheme: xUseTheme
})

export type { CSSObject } from "@emotion/react"

// pass through
export const useTheme = xUseTheme
