import debounce from 'lodash/debounce'

$(function () {
  // We only support one auto save form per page
  var $form = $('.js-autosave').first();
  var ajaxSaving = false;
  var pageSubmitting = false;
  var formDirtyEvents = 0;
  var formSavedEvents = 0;

  if ($form.length == 0) {
    return;
  }

  var markFormSaving = function() {
    $form.addClass('form-saving');
  }

  var clearFormSaving = function() {
    $form.removeClass('form-saving');
  }


  window.registerBeforeUnload(function() {
    if (!pageSubmitting && (formDirtyEvents > formSavedEvents)) {
      return "This page is still saving your data, are you sure you want to leave?";
    }
  });

  $form.on('submit', function(evt) {
    if (pageSubmitting || ajaxSaving) {
      evt.preventDefault();
    } else {
      markFormSaving();
      pageSubmitting = true;
    }
  });

  var enqueueSave = debounce(
    function() {
      var savingEvents;
      if (pageSubmitting) { return }
      $.ajaxQueue({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serializeArray(),
        beforeSend: function() {
          ajaxSaving = true;
          savingEvents = formDirtyEvents;
          markFormSaving();
        },
      }).done(function(data) {
        if (data.lockVersion) {
          $form.find('.j-lock-version').val(data.lockVersion);
        }
        formSavedEvents = savingEvents;
        return this;
      }).always(function() {
        clearFormSaving();
        ajaxSaving = false;
      });
    },
    4000,
    {maxWait: 8000}
  );

  var formChange = function() {
    formDirtyEvents += 1
    enqueueSave();
  }

  $form.on('change', 'select', formChange);
  $form.on('input change js-change', 'input', formChange);
  $form.on('input change js-change', 'textarea', formChange);
});
