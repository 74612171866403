$(function() {
  var ajaxTimeout = 8000;
  $.ajaxSetup({
    timeout: ajaxTimeout //Time in milliseconds
  });

  var handleAjaxError = function(_e, jqXHR, _s, thrownError)  {
    // ignore intentional aborts
    if (thrownError == 'abort') {
      return
    }
    if (!jqXHR) {
      Sentry.withScope(function(scope) {
        scope.setExtra("jq-error-args", [_e, jqXHR, _s, thrownError]);
        Sentry.captureMessage('blank xhr');
      });
      return;
    }
    var msg = null;
    var json=jqXHR.responseJSON;
    var html=jqXHR.responseText;
    var statusMessage;
    if (thrownError === 'timeout') {
      msg = $('<p>').text('The server is taking too long to respond. '+
        'There may be a problem with your internet connection or we may under heavy load.'
      )
    } else if (thrownError == 'parseerror') {
      msg = $('<p>').text('The server is not responding correctly. '+
        'Please let us know or try again later.')
    } else if (jqXHR.status === 0)  {
      msg = $('<p>').text('The server is not responding. '+
        'There may be a problem with your internet connection.'
      )
    } else if (json && (statusMessage = json.status_message || json.message || json.error) != '') {
      msg = $('<p>').text(statusMessage)
    } else if (html && html != '') {
      if (jqXHR.status >= 500) {
        msg = $('<p>').text("We're sorry, but something went wrong. We've been notified about this issue and we'll take a look at it shortly.");
      } else {
        msg = $('<div>').html(html)
      }
    }
    if (jqXHR.status == 403 && msg && msg.find('#new_login').length) {
      window.modalApi.showContent(msg.html(), 'Sign In to continue')
    } else {
      window.modalApi.showError(msg ? msg.html() : null);
    }
  };
  $(document).ajaxError(handleAjaxError);

  // remote form error handling fix
  Rails.delegate(document, 'form', 'ajax:error', function(event) {
    var ref = event.detail, status = ref[1], xhr = ref[2];
    handleAjaxError(null, xhr, status, status);
  });

  // remote form submissions: if location then redirect, else render in new modal
  Rails.delegate(document, 'form', 'ajax:success', function(event) {
    window.modalApi.setConfirmClose(false);

    var ref = event.detail, data = ref[0], xhr = ref[2];
    var $parsed = $(data);

    var url = xhr.getResponseHeader('Location')
    if (url) {
      if (xhr.getResponseHeader('Location-Disposition') === 'modal') {
        window.modalApi.setState({
          contentUrl: url,
          status: 'requested'
        });
      } else {
        window.modalApi.showLoading();
        window.location = url;
      }
      return;
    }

    window.modalApi.showContent(
      $parsed.find('#modal-payload-body').html(),
      $parsed.find('#modal-payload-title').html()
    );
  });

});
