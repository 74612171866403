import { ReactNodeLike } from "prop-types"
import React, { ErrorInfo } from "react"
import EnvironmentErrorDialog from "./ErrorDialog"
import logToSentry from "./logToSentry"

interface State {
  hasError: boolean
}
interface Props {
  children: ReactNodeLike
  inline?: boolean
}

class EnvironmentErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info?: ErrorInfo) {
    this.setState({ hasError: true })
    logToSentry(error, info)
  }

  render() {
    const { hasError } = this.state
    const { inline = true } = this.props
    if (hasError) {
      return <EnvironmentErrorDialog variant="default" inline={inline} />
    }
    return this.props.children
  }
}

export default EnvironmentErrorHandler
