import React, {  useCallback } from 'react';
import { useStateValue } from '../../state';
import { SrgSite } from '../../types';
import ContentLabeledBooleanTag from 'Content/Labeled/LabeledBooleanTag';

const SiteForm = (
  props: {
    site: SrgSite
    rowPrefix: string
  }
) => {
  const { site, rowPrefix } = props
  const [, dispatch] = useStateValue()

  const handleDestroy = useCallback(
    () => {
      dispatch({ type: 'SITE_REMOVED', data: { site: site.site } })
    },
    [dispatch, site]
  )

  const canDestroy = true

  return (
    <div>
      {site.site.id && <input type="hidden" name={rowPrefix + "[id]"} value={site.site.id} />}

      <div className="d-flex justify-content-between align-items-center">
        <div className="container">
          <div className='row'>
            <div className='col-md-4'>
              <div className="mb-0">
                <div>
                  <span className="text-id-value-pfx">Site ID</span>
                  <span className="text-id-value">{site.site.id}</span>
                </div>
                <h3 className="mb-0">
                  <a href={site.site.url} className="text-color-black" target="ror_site">{site.site.name}</a>
                </h3>
              </div>
            </div>
            <div className="col-md-8 align-items-center d-flex">
              <ContentLabeledBooleanTag
                name={rowPrefix + "[use_site_reporting_group_for_site_reports]"}
                value={site.useAggregateData ? 'true' : 'false'}
                label="Use aggregate data for this site and all satellites"
                checked={site.useAggregateData === true}
                onChange={() => {
                  dispatch({ type: 'SITE_AGGREGATION_UPDATED', data: { site: site.site, useAggregateData: !site.useAggregateData } })
                }}
              />
            </div>
          </div>
        </div>


        {canDestroy && <div>
          <button
            type="button"
            className="btn btn-link"
            onClick={handleDestroy}
          ><i className="i-remove" /></button>
        </div>
        }
      </div>
    </div>
  )
}
export default SiteForm
