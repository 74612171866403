import React, { Component, ReactElement } from 'react'
import sortBy from 'lodash/sortBy'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { DataTableProps, Measure } from '../types'
import trim from 'lodash/trim'

const numericRgx = /^[0-9]*\.?[0-9]*$/

type State = {
  totalUpdated: boolean
  chosenMeasureIds: Map<string, number>
  measureOrder: number
}
const duration = 300
export default class SiteDataTable extends Component<DataTableProps, State>{

  constructor(props: DataTableProps) {
    super(props)
    const { measures, question } = props

    let chosenMeasureIds = new Map()
    let measureOrder = 0
    measures.forEach(measure => {
      if (!question.dynamicRows || measure.answerValue) {
        chosenMeasureIds.set(measure.id, measureOrder += 1)
      }
    })
    this.state = {
      totalUpdated: false,
      chosenMeasureIds,
      measureOrder,
    }
  }

  timeout: number | undefined
  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout)
    }
  }
  UNSAFE_componentWillReceiveProps(newProps: DataTableProps) {
    if (newProps.totalValue != this.props.totalValue) {
      if (this.timeout) {
        window.clearTimeout(this.timeout)
      }
      this.setState({ totalUpdated: true })
      this.timeout = window.setTimeout(
        () => { this.setState({ totalUpdated: false }) },
        2000
      )
    }
  }

  render() {
    const { question, locationName, measures } = this.props
    const { chosenMeasureIds } = this.state

    const chosenValueCb = (measure: Measure) => chosenMeasureIds.get(measure.id)
    const chosenMeasures = sortBy(measures.filter(chosenValueCb), chosenValueCb)

    return (<div>
      {this.renderHiddenMeasures()}
      <table className="table table-bordered table-sm mb-0 calculated-results__table">
        <colgroup>
          <col></col>
          <col width="200px"></col>
          {question.dynamicRows && <col width="60px"></col>}
        </colgroup>
        <thead>
          <tr>
            <th className="text-right">{question.label}</th>
            <th className="text-center" colSpan={question.dynamicRows ? 2 : 1} >
              <div>{question.hint}</div>
              <div className="calculated-results__secondary-label">at {locationName}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <TransitionGroup component={null}>{
            chosenMeasures.map(
              (measure) => (
                <CSSTransition key={measure.id} timeout={duration} classNames="fade">
                  <tr className="s2-row-tx">{
                    this.renderMeasureRow(measure)
                  }</tr>
                </CSSTransition>
              )
            )
          }</TransitionGroup>
          {this.renderAddRow()}
          {this.renderTotalRow()}
        </tbody>
      </table>
    </div>
    )
  }

  renderHiddenMeasures() {
    const { measures } = this.props
    const { chosenMeasureIds } = this.state

    const hiddenMeasures = measures.filter(
      (measure) => !chosenMeasureIds.get(measure.id)
    )

    let inputs: ReactElement[] = []
    hiddenMeasures.forEach((measure) => {
      inputs.push(<input
        type="hidden"
        name={measure.answerField}
        value=""
        key={`answerField${measure.id}`}
      />)
      if (measure.otherField) {
        inputs.push(
          <input
            type="hidden"
            name={measure.otherField}
            value=""
            key={`otherField${measure.id}`}
          />)
      }
    })
    return inputs
  }

  renderAddRow() {
    const { question, measures } = this.props
    const { chosenMeasureIds } = this.state
    const optionalMeasures = measures.filter((measure) => !chosenMeasureIds.get(measure.id))

    if (optionalMeasures.length === 0) {
      return
    }
    return <tr key='addRow'>
      <td className="text-right" style={{ verticalAlign: "middle" }}>
        <select value="none" onChange={this.handleMeasureToggle}>
          <option value="none"> -- Add {question.label}</option>
          {optionalMeasures.map((measure) => <option key={measure.id} value={measure.id}>{measure.label}</option>)}
        </select>
      </td>
      <td colSpan={2}></td>
    </tr>
  }

  totalErrorMessage() {
    const { totalValue, validated, validationCondition} = this.props
    if (validationCondition === 'sum100') {
      if (validated && totalValue != 100 || totalValue > 100) {
        return 'should equal 100%'
      }
    } else {
      if (validated && totalValue < 100) {
        return 'should be at least 100%'
      }
      if (totalValue >= 200) {
        return 'should be less than 200%'
      }
    }
    if (validated && !(totalValue > 0)) {
      return 'should be greater than 0%'
    }
  }

  isComplete() {
    const { totalValue, validationCondition} = this.props
    if (validationCondition === 'sum100') {
      return totalValue === 100
    } else {
      return totalValue >= 100 && totalValue < 200
    }
  }

  renderTotalRow() {
    const { question, totalValue, validationCondition} = this.props
    const { totalUpdated } = this.state

    const errorMessage = this.totalErrorMessage()
    const infoMessage = validationCondition === 'sum100' ? 'should equal 100%' : 'should be at least 100%'
    const message = errorMessage || infoMessage
    const rowClass = errorMessage ? 'text-danger-dark' :
      (this.isComplete() ? 'text-success' : null)
    return <tr key="totalRow" className="calculations-row--answer">
      <td className="text-right" style={{ verticalAlign: "middle" }}>
        Total {' '} {message && <span className={(rowClass || "text-muted-dark") + " text-right"}>{message}</span> }
      </td>
      <td
        className={"text-right pr-4 calculations__answer " + rowClass + " " + (totalUpdated ? 'updated' : '')}
        colSpan={question.dynamicRows ? 2 : 1}
      >
        {totalValue} %
      </td>
    </tr>
  }

  renderMeasureRow(measure: Measure) {
    const { question } = this.props
    return (
      <>
        <td className="text-right" style={{ verticalAlign: "middle" }}>
          {measure.otherField ? this.renderOtherInput(measure) : measure.label}
          {measure.errors.map(msg => <div key={msg} className='text-danger'>{msg}</div>)}
        </td>
        <td>
          {this.renderAnswerInput(measure)}
        </td>
        {question.dynamicRows && <td><button
          className="btn btn-secondary btn-sm btn-block"
          type="button"
          value={measure.id}
          onClick={this.handleMeasureToggle}
        >&times;</button></td>}
      </>
    )
  }

  renderOtherInput(measure: Measure) {
    return <div>
      <label className="d-inline-block mr-2">{measure.label}</label>
      <input
        style={{ width: "200px", flexGrow: 0 }}
        className="form-control form-control-sm d-inline-block m-0"
        type="text"
        data-measure={measure.id}
        name={measure.otherField}
        value={measure.otherValue ? measure.otherValue : ''}
        placeholder='please specify'
        onChange={this.handleOtherChange}
      /></div>
  }

  renderAnswerInput(measure: Measure) {
    return <div className="input-group input-group-sm">
      <input
        className="form-control form-control-sm m-0 text-right"
        type="text"
        data-measure={measure.id}
        data-value={measure.answerValue}
        onInput={this.handleAnswerChange}
        onChange={this.handleAnswerChange}
        name={measure.answerField}
        value={measure.answerValue ? measure.answerValue : ''}
      />
      <div className="input-group-append">
        <div className="input-group-text">%</div>
      </div>
    </div>
  }

  handleOtherChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { onValueChange } = this.props
    const measureId = evt.currentTarget.getAttribute('data-measure')
    if (measureId) {
      onValueChange(measureId, { otherValue: evt.currentTarget.value })
    } else {
      throw new Error("missing data-measure attr");
    }
  }

  handleAnswerChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { onValueChange } = this.props
    const stringValue = String(trim(evt.currentTarget.value))
    const measureId = evt.currentTarget.getAttribute('data-measure')
    if (measureId) {
      if (stringValue.match(numericRgx)) {
        onValueChange(measureId, { answerValue: stringValue })
      } else {
        onValueChange(measureId, { answerValue: evt.currentTarget.getAttribute('data-value') })
      }
    } else {
      throw new Error("missing data-measure attr");
    }
  }

  handleMeasureToggle = (evt: React.SyntheticEvent<{ value: string }>) => {
    const measureId = evt.currentTarget.value
    this.setState(({chosenMeasureIds, measureOrder}, {onValueChange}) => {
      const newChosenMeasureIds = new Map(chosenMeasureIds)
      const newValue = chosenMeasureIds.get(measureId) ? false : (measureOrder += 1)

      if (newValue) {
        newChosenMeasureIds.set(measureId, newValue)
      } else {
        newChosenMeasureIds.delete(measureId)
        onValueChange(measureId, { answerValue: null, otherValue: null })
      }

      return({
        chosenMeasureIds: newChosenMeasureIds,
        measureOrder: measureOrder,
      })
    })
  }

}
