import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStateValue } from '../../state';
import SiteContactArtifact from './SiteArtifact';
import SiteContactForm from './SiteForm';

const duration = 500

const SiteList = () => {
  const [{ modified, formPrefix, sites }] = useStateValue();
  useEffect(
    () => {
      if (modified) {
        //@ts-ignore
        window.modalApi ? window.modalApi.setConfirmClose(true) : null
      }
    },
    [modified]
  )

  return (
    <ul className="card-list dash__card mb-4">
      {sites.length === 0 && (
        <li className="s2-card-tx contact-card list-item bg-light text-center">
          No Sites Selected
        </li>
      )}
      <TransitionGroup component={null}>{
        sites.map(
          (site, idx) => {
            const rowPrefix = `${formPrefix}[${idx}]`
            if (site.deleted) {
              return (
                <CSSTransition key={site.site.id + 'artifact'} timeout={duration} classNames="artifact">{
                  () => (
                    <li className="s2-card-tx contact-card list-item bg-light">
                      <SiteContactArtifact
                        site={site}
                        rowPrefix={rowPrefix}
                      />
                    </li>
                  )
                }</CSSTransition>
              )
            } else {
              return (
                <CSSTransition key={site.site.id + 'form'} timeout={duration} classNames={site.site.id ? 'shrink' : 'slide'}>{
                    <li className="s2-card-tx contact-card list-item">
                      <SiteContactForm
                        site={site}
                        rowPrefix={rowPrefix}
                      />
                    </li>
                }</CSSTransition>
              )
            }
          }
        )
      }</TransitionGroup>
    </ul>
  )
}
export default SiteList
