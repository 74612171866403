$(function() {
  var $root = $('#j-root');
  var $fly = $('#j-ubr-fly');
  $('.j-fly-toggle').click(function() {
    $fly.addClass("animatable");
    $root.toggleClass('ubr-fly-open');
  })

  var onTransitionEnd = function() {
    $fly.removeClass("animatable");
  }
  $fly.on("transitionend", onTransitionEnd, false);

  var setFlyHeight = function() {
    $fly.css('height', $(window).height() - 60);
  };
  setFlyHeight();
  $(window).on("resize", setFlyHeight);

  $('.j-fly-submenu-toggle').click(function() {
    $(this).closest('.j-fly-submenu').toggleClass('open')
    $(this).toggleClass('active')
  });

  $(document).one('click', '.j-page-reload', function() {
    window.location.reload(true);
  });

  $(document).on('click', '.j-toggle-expandy', function(evt) {
    evt.preventDefault();
    $(evt.target).next('.j-expandy').toggleClass('open')
  });

  $(document).on('click', '.j-double-click-safey', function(evt) {
    var $e = $(this);
    if ( $e.data("clicked") ) {
      evt.preventDefault();
    } else {
      $e.data("clicked", true);
      setTimeout(function() {
        $e.data("clicked", false);
      }, 3000);
    }
  });

});
